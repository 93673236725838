import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  ActivityIndicator,
  Text,
  View,
  ScrollView,
  Image,
  ImageBackground,
  TextInput,
  TouchableOpacity,
  Button,
  Modal as Modal_new,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { scale, colors, device, wp, hp } from "../utils";
import * as WebBrowser from "expo-web-browser";
import { ResponseType } from "expo-auth-session";
import * as Google from "expo-auth-session/providers/google";
import { initializeApp } from "firebase/app";
import * as Facebook from "expo-auth-session/providers/facebook";
import {
  header_logo,
  search_icon,
  cloud_icon,
  upgrade_icon,
  upload_icon,
  arrow_left,
} from "../assets";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reduxStore/store";
import { NETWORK_URL } from "../utils/config";
import { storeToken, userDetails } from "../reduxStore/actions";
import { CommonActions, useFocusEffect } from "@react-navigation/native";
import LinearGradient from "react-native-web-linear-gradient";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import Home from "./Home";
import Modal from "react-native-modal";
import CreatePostScreen from "./CreatePostScreen";
import Autocomplete from "react-autocomplete";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpgradeSelect from "./UpgradeSelect";

WebBrowser.maybeCompleteAuthSession();

initializeApp({
  apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
  authDomain: "farmhomes-bcefb.firebaseapp.com",
  projectId: "farmhomes-bcefb",
  storageBucket: "farmhomes-bcefb.appspot.com",
  messagingSenderId: "718737440796",
  appId: "1:718737440796:web:395567042f5eee01329fd9",
  measurementId: "G-NGCNHJQGG5",
});

export default function Navigation({ navigation, children }: any) {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<String | null>(null);
  const [message, setMessage] = useState<any>(null);
  const user = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch();
  const [modalVisible, setmodalVisible] = useState<any>(false);
  const [modalVisibleother, setmodalVisibleother] = useState<any>(false);

  const [modaladdpost, setmodaladdpost] = useState<any>(false);

  const [total_storage, settotal_storage] = useState<any>(0);

  var [event_created, setevent_created] = useState<any>([]);
  const [event_joined, setevent_joined] = useState<any>([]);
  const [event_closed, setevent_closed] = useState<any>([]);
  const [uploading_goingon, setuploading_goingon] = useState<any>(false);

  const [all_events, setall_events] = useState<any>([]);
  const [event_typed, setevent_typed] = useState<any>("");
  const [isHovered, setIsHovered] = useState(false);

  const [upgradealert, showupgradealert] = useState(false);
  const [planselect, setplanselect] = useState(false);

  const [subscribed_till, setsubscribed_till] = useState<any>("");

  const [plan_warning, setplan_warning] = useState<any>("");
  const [createdText, setCreatedText] = useState<any>("");
  const [joinedText, setJoinedText] = useState<any>("");
  const [closedText, setClosedText] = useState<any>("");

  const [raw_available, setraw_available] = useState<any>(0);

  const notify = (text) => toast(text);

  const [eventcreatefinish, seteventcreatefinish] = useState(false);

  const [eventjoinfinish, seteventjoinfinish] = useState(false);

  const [eventclosedfinish, seteventclosedfinish] = useState(false);

  const [create_page_limit, setcreate_page_limit] = useState(1);

  const [join_page_limit, setjoin_page_limit] = useState(1);

  const [closed_page_limit, setclosed_page_limit] = useState(1);

  const [userProfile, setUserProfile] = useState({});

  function getallevents(name) {
    axios
      .get(
        NETWORK_URL + "/eventApi/search?name=" + name + "&limit=1000&page=1",
        {
          headers: {
            "x-access-token": user.auth_token,
          },
        }
      )
      .then((response) => {
        console.log("response.data", response.data);
        setall_events(response.data);
        if (name !== "" && response.data.length === 0)
          setall_events([
            { _id: "626fbc9940480900239e5fdq", name: "No event found" },
          ]);
      })
      .catch(function (error: any) {
        // alert(error.response.data.message);
      });
  }

  function get_upload_status() {
    var myInterval = setInterval(function () {
      axios
        .get(`${NETWORK_URL}/postApi/get_upload_status`, {
          headers: {
            "x-access-token": user.auth_token,
          },
        })
        .then((response) => {
          //    alert(response.data.upload_active);

          setuploading_goingon(response.data.upload_active);

          if (!response.data.upload_active) {
            clearInterval(myInterval);
          }
        })
        .catch(function (error: any) {
          alert(error.response.data.message);
        });
    }, 1000);
  }

  function get_user_profile() {
    axios
      .get(`${NETWORK_URL}/userApi`, {
        headers: {
          "x-access-token": user.auth_token,
        },
      })
      .then((response) => {
        // alert(JSON.stringify(response.data));

        setUserProfile(response.data);

        settotal_storage(response.data.size_json.available);

        setsubscribed_till(response.data.subscription_valid_till);

        setraw_available(response.data.size_json.raw_available);

        // alert(response.data.warning_plan);

        if (response.data.warning_plan)
          setplan_warning(response.data.warning_plan);
      })
      .catch(function (error: any) {
        console.log(error.response.data.message);
      });
  }

  function GetEvent(status, user_id, name = "") {
    axios
      .get(`${NETWORK_URL}/eventApi/get`, {
        params: {
          status: status,
          user_id: user_id,
          name: name,
        },
        headers: {
          "x-access-token": user.auth_token,
        },
      })
      .then((response) => {
        if (name != "") {
          if (status == "Created") {
            setevent_created(response.data);
          }
          if (status == "Joined") {
            setevent_joined(response.data);
          }
          if (status == "Closed") {
            setevent_closed(response.data);
          }
        } else {
          if (status == "Created") {
            setevent_created(response.data);
          }
          if (status == "Joined") {
            setevent_joined(response.data);
          }
          if (status == "Closed") {
            setevent_closed(response.data);
          }
        }
      })
      .catch(function (error: any) {
        alert(error.response.data.message);
      });
  }

  const getEventsCreated = (page = 1, name = "") => {
    // alert(NETWORK_URL + '/eventApi/get?user_id=' + user.userDetails._id + '&status=Created&page=' + page + '&limit=10&name='+name);

    // alert(name);

    if (name != "") {
      axios({
        method: "get",
        url:
          NETWORK_URL +
          "/eventApi/search?name=" +
          name +
          "&limit=1000&page=1&status=Created",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.auth_token,
        },
      })
        .then((response) => {
          setevent_created(response.data);
          seteventcreatefinish(true);
          setcreate_page_limit(1);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    } else {
      axios({
        method: "get",
        url:
          NETWORK_URL +
          "/eventApi/get?user_id=" +
          user.userDetails._id +
          "&status=Created&page=" +
          page +
          "&limit=10&name=" +
          name,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.auth_token,
        },
      })
        .then((response) => {
          console.log("Response.................", response.data);
          if (page == 1) setevent_created(response.data);
          else setevent_created(event_created.concat(response.data));
          if (response.data.length <= 0) seteventcreatefinish(true);
          else seteventcreatefinish(false);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    }
  };

  const getEventsJoined = (page = 1, name = "") => {
    if (name != "") {
      axios({
        method: "get",
        url:
          NETWORK_URL +
          "/eventApi/search?name=" +
          name +
          "&limit=1000&page=1&status=Joined",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.auth_token,
        },
      })
        .then((response) => {
          setevent_joined(response.data);
          seteventjoinfinish(true);
          setjoin_page_limit(1);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    } else {
      axios({
        method: "get",
        url:
          NETWORK_URL +
          "/eventApi/get?user_id=" +
          user.userDetails._id +
          "&status=Joined&page=" +
          page +
          "&limit=10&name=" +
          name,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.auth_token,
        },
      })
        .then((response) => {
          console.log("Response.................", response.data);
          if (page == 1) setevent_joined(response.data);
          else setevent_joined(event_joined.concat(response.data));
          if (response.data.length <= 0) seteventjoinfinish(true);
          else seteventjoinfinish(false);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    }
  };

  const getEventsClosed = (page = 1, name = "") => {
    if (name != "") {
      axios({
        method: "get",
        url:
          NETWORK_URL +
          "/eventApi/search?name=" +
          name +
          "&limit=1000&page=1&status=Closed",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.auth_token,
        },
      })
        .then((response) => {
          setevent_closed(response.data);
          seteventclosedfinish(true);
          setclosed_page_limit(1);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    } else {
      axios({
        method: "get",
        url:
          NETWORK_URL +
          "/eventApi/get?user_id=" +
          user.userDetails._id +
          "&status=Closed&page=" +
          page +
          "&limit=10&name=" +
          name,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.auth_token,
        },
      })
        .then((response) => {
          console.log("Response.................", response.data);
          if (page == 1) setevent_closed(response.data);
          else setevent_closed(event_closed.concat(response.data));
          if (response.data.length <= 0) seteventclosedfinish(true);
          else seteventclosedfinish(false);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    }
  };

  useEffect(() => {
    // get_upload_status();
    get_user_profile();

    // GetEvent("Created", user.userDetails._id);
    // GetEvent("Joined", user.userDetails._id);
    // GetEvent("Closed", user.userDetails._id);

    getEventsCreated();
    getEventsJoined();
    getEventsClosed();

    if (user.auth_token === "") {
      window.location.href = "/";
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const search_event_created = () => {
    if (createdText.length === 0) return event_created;
    return event_created.filter((item) =>
      item.name.toUpperCase().includes(createdText.toUpperCase())
    );
  };

  const search_event_joined = () => {
    if (joinedText.length === 0) return event_joined;
    return event_joined.filter((item) =>
      item.name.toUpperCase().includes(joinedText.toUpperCase())
    );
  };

  const search_event_closed = () => {
    if (closedText.length === 0) return event_closed;
    return event_closed.filter((item) =>
      item.name.toUpperCase().includes(closedText.toUpperCase())
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 5,
          paddingVertical: 4,
          justifyContent: "space-between",
          borderBottomColor: "#C6C6C6",
          zIndex: 9999,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {wp(100) <= 426 && (
            <TouchableOpacity
              onPress={() => {
                setmodalVisible(!modalVisible);
              }}
            >
              <Image
                source={{
                  uri: "http://dce.rocketausgate.com/_images/menu-icon.png",
                }}
                style={{ width: hp(3), height: hp(3) }}
                resizeMode={"contain"}
              />
            </TouchableOpacity>
          )}
          <Image
            source={header_logo}
            style={{
              marginLeft: 10,
              width: wp(100) <= 426 ? wp(20) : wp(10),
              height: hp(5),
            }}
            resizeMode={"contain"}
          />

          {wp(100) > 426 && (
            <View
              style={{
                marginLeft: wp(100) <= 426 ? wp(3) : wp(5) - 10,
                width: wp(100) <= 426 ? wp(50) : wp(20),
                height: hp(4),
                flexDirection: "row",
                alignItems: "center",
                borderBottomColor: "white",
                borderBottomWidth: 0,
              }}
            >
              <Image
                source={search_icon}
                style={{ width: 10, height: 10, marginLeft: 10 }}
              />
              <Autocomplete
                getItemValue={(item) => item._id}
                items={all_events}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={{
                      background: isHighlighted ? "lightgray" : "white",
                      cursor: "pointer",
                      zIndex: 2147483647,
                    }}
                  >
                    {item.name}
                  </div>
                )}
                value={event_typed}
                onChange={(e) => {
                  setevent_typed(e.target.value);
                  if (e.target.value == "") return false;
                  setTimeout(function () {
                    getallevents(e.target.value);
                  }, 200);
                }}
                onSelect={(val) => {
                  event_typed !== "" && all_events[0].name === "No event found"
                    ? null
                    : (window.location.href = window.location.href.substring(0, window.location.href.toString().lastIndexOf("/")) + "/" + window.btoa(val))
                }}
                placeholder={"Search events "}
              />
            </View>
          )}
        </View>

        {wp(100) > 426 && (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {/* {subscribed_till == "" && plan_warning == "" && <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image source={cloud_icon} style={{ width: wp(2), height: hp(5) }} resizeMode={"contain"} />
                        <View style={{ alignItems: "center", marginLeft: 10 }}>
                            <Text style={{ color: "white" }}>{total_storage}</Text>
                            <Text style={{ color: "white", fontSize: 12, marginTop: 4 }}>Storage</Text>
                        </View>
                    </View>}

                    {subscribed_till != "" && <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <View style={{ alignItems: "center", marginLeft: 10 }}>
                            <Text style={{ color: "white" }}>You are Subscribed</Text>
                            <Text style={{ color: "white", fontSize: 12, marginTop: 4 }}>Valid Till {subscribed_till}</Text>
                        </View>
                    </View>}

                    {plan_warning != "" && <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <View style={{ alignItems: "center", marginLeft: 10 }}>
                            <Text style={{ color: "white" }}>Subscription ended!</Text>
                            <Text style={{ color: "white", fontSize: 12, marginTop: 4 }}>{plan_warning}</Text>
                        </View>
                    </View>} */}

            {subscribed_till != "" && (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={cloud_icon}
                  style={{ width: wp(2), height: hp(5) }}
                  resizeMode={"contain"}
                />
                <View style={{ marginLeft: 5, alignItems: "center" }}>
                  <Text style={{ color: "white" }}>
                    {userProfile?.size_json?.used} /{" "}
                    {userProfile?.planData?.maxStorage != -1
                      ? userProfile?.planData?.maxStorage
                      : "Unlimited"}
                  </Text>
                  <Text style={{ color: "white", fontSize: 12 }}>Storage</Text>
                </View>
              </View>
            )}
            {subscribed_till == "" ? (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={cloud_icon}
                  style={{ width: wp(2), height: hp(5) }}
                  resizeMode={"contain"}
                />
                <View style={{ marginLeft: 5, alignItems: "center" }}>
                  <Text style={{ color: "white" }}>
                    {userProfile?.size_json?.raw_used >=
                    userProfile?.size_json?.raw_total
                      ? "100.00 MB"
                      : userProfile?.size_json?.used}
                    /{userProfile?.size_json?.total}
                  </Text>
                  <Text style={{ color: "white", fontSize: 12 }}>Storage</Text>
                </View>
              </View>
            ) : (
              <></>
            )}

            {subscribed_till == "" && (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  marginLeft: wp(3),
                  alignItems: "center",
                  borderColor: "#FFFFFF",
                  borderWidth: 1,
                  borderRadius: 5,
                  paddingHorizontal: 9,
                  paddingVertical: 5,
                }}
                onPress={() => {
                  // alert("");
                  // if (document.cookie.indexOf(CookieName) != -1) {
                  //   console.log("Thank you for enabling Third-Party cookies");
                  // }

                  setplanselect(true);
                }}
              >
                <Image
                  source={upgrade_icon}
                  style={{ width: 20, height: 20 }}
                />
                <Text style={{ marginLeft: 10, color: "white" }}>Upgrade</Text>
              </TouchableOpacity>
            )}

            {!uploading_goingon ? (
              <TouchableOpacity
                style={{
                  backgroundColor: colors.primary,
                  marginLeft: wp(3),
                  borderRadius: wp(2),
                }}
                onPress={() => {
                  setmodaladdpost(true);
                }}
              >
                <LinearGradient
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  colors={["#FF5C5B", "#20CCD6"]}
                  style={{
                    paddingHorizontal: wp(2),
                    paddingVertical: 10,
                    borderRadius: wp(1),
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      source={upload_icon}
                      style={{ width: 20, height: 20 }}
                    />
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 14,
                        color: "white",
                        marginLeft: 5,
                      }}
                    >
                      Upload
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <ActivityIndicator size="small" color="#ffffff" />
                <Text style={{ color: "white", marginLeft: 5, fontSize: 14 }}>
                  Uploading
                </Text>
              </View>
            )}
            <Menu
              menuStyle={{
                backgroundColor: "#0B0B14",
                marginTop: "10px",
                marginLeft: "-10px",
                borderRadius: 15,
              }}
              menuButton={
                <Image
                  source={{
                    uri: user?.userDetails?.profile_picture
                      ? user?.userDetails?.profile_picture
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
                  }}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                    marginLeft: wp(2),
                  }}
                  resizeMode={"cover"}
                />
              }
              transition
              // align={'start'} position={'start'}
              // direction={'right'}
            >
              <MenuItem
                onFocus={() => setIsHovered(isHovered)}
                style={
                  isHovered
                    ? { backgroundColor: "#0B0B14", margin: "10px" }
                    : { backgroundColor: "#0B0B14", margin: "10px" }
                }
              >
                <View style={{ width: "300px", marginLeft: "15%" }}>
                  <Image
                    source={{
                      uri: user?.userDetails?.profile_picture
                        ? user?.userDetails?.profile_picture
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
                    }}
                    style={{
                      width: 150,
                      height: 150,
                      borderRadius: 75,
                      marginLeft: wp(2),
                    }}
                    resizeMode={"cover"}
                  />
                </View>
              </MenuItem>
              <MenuItem
                onFocus={() => setIsHovered(isHovered)}
                style={
                  isHovered
                    ? { backgroundColor: "#0B0B14", margin: "10px" }
                    : { backgroundColor: "#0B0B14", margin: "10px" }
                }
              >
                <View style={{ width: "300px" }}>
                  <Text style={{ color: "#8D8D93" }}>Username</Text>
                  <Text style={{ color: "white" }}>
                    @ {user?.userDetails?.username}
                  </Text>
                  <Text
                    style={{
                      backgroundColor: "#8D8D93",
                      height: "1px",
                      marginTop: "10px",
                    }}
                  ></Text>
                </View>
              </MenuItem>
              <MenuItem
                onFocus={() => setIsHovered(isHovered)}
                style={
                  isHovered
                    ? { backgroundColor: "#0B0B14", margin: "10px" }
                    : { backgroundColor: "#0B0B14", margin: "10px" }
                }
              >
                <View style={{ width: "300px" }}>
                  <Text style={{ color: "#8D8D93" }}>Email</Text>
                  <Text style={{ color: "white" }}>
                    {user?.userDetails?.email}
                  </Text>
                  <Text
                    style={{
                      backgroundColor: "#8D8D93",
                      height: "1px",
                      marginTop: "10px",
                    }}
                  ></Text>
                </View>
              </MenuItem>
              <MenuItem
                onFocus={() => setIsHovered(isHovered)}
                style={
                  isHovered
                    ? { backgroundColor: "#0B0B14", margin: "10px" }
                    : { backgroundColor: "#0B0B14", margin: "10px" }
                }
                onClick={() => handleLogout()}
              >
                <View style={{ marginLeft: "40%" }}>
                  <Text style={{ color: "red", fontWeight: "600" }}>
                    Sign Out
                  </Text>
                </View>
              </MenuItem>
            </Menu>
          </View>
        )}

        {wp(100) <= 426 && (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {!uploading_goingon ? (
              <TouchableOpacity
                style={{
                  backgroundColor: colors.primary,
                  borderRadius: wp(2),
                  marginRight: 10,
                }}
                onPress={() => {
                  setmodaladdpost(true);
                }}
              >
                <LinearGradient
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  colors={["#FF5C5B", "#20CCD6"]}
                  style={{
                    paddingHorizontal: wp(2),
                    paddingVertical: 10,
                    borderRadius: wp(1),
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      source={upload_icon}
                      style={{ width: 20, height: 20 }}
                    />
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 14,
                        color: "white",
                        marginLeft: 5,
                      }}
                    >
                      Upload
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <ActivityIndicator size="small" color="#ffffff" />
                <Text style={{ color: "white", marginLeft: 5, fontSize: 14 }}>
                  Uploading
                </Text>
              </View>
            )}

            <TouchableOpacity
              onPress={() => {
                setmodalVisibleother(!modalVisibleother);
              }}
            >
              <Image
                source={{
                  uri: user?.userDetails?.profile_picture
                    ? user?.userDetails?.profile_picture
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
                }}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 40,
                  marginLeft: wp(1),
                }}
                resizeMode={"cover"}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      {wp(100) <= 426 && (
        <View
          style={{
            marginLeft: wp(100) <= 426 ? wp(3) : wp(5) - 10,
            width: wp(100) <= 426 ? wp(50) : wp(20),
            height: hp(4),
            flexDirection: "row",
            alignItems: "center",
            borderBottomColor: "white",
            borderBottomWidth: 0,
          }}
        >
          <Image
            source={search_icon}
            style={{ width: 10, height: 10, marginLeft: 10 }}
          />
          <Autocomplete
            getItemValue={(item) => item._id}
            items={all_events}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  cursor: "pointer",
                  zIndex: 2147483647,
                }}
              >
                {item.name}
              </div>
            )}
            value={event_typed}
            onChange={(e) => {
              setevent_typed(e.target.value);
              if (e.target.value == "") return false;
              setTimeout(function () {
                getallevents(e.target.value);
              }, 200);
            }}
            onSelect={(val) => {
              event_typed !== "" && all_events[0].name === "No event found"
              ? null
              : (window.location.href = window.location.href.substring(0, window.location.href.toString().lastIndexOf("/")) + "/" + window.btoa(val))
            }}
            placeholder={"Search events "}
          />
        </View>
      )}

      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          zIndex: -9999,
        }}
      >
        {wp(100) > 426 && (
          <View
            style={{
              width: "15%",
              height: "100%",
              borderRightColor: "#C6C6C6",
              paddingTop: hp(3),
              paddingLeft: "2%",
              paddingBottom: hp(0),
            }}
          >
            <TouchableOpacity
              style={{ height: hp(3), justifyContent: "center" }}
              onPress={() => {
                navigation.push("Home");
              }}
            >
              <Text style={{ fontWeight: "bold", color: "white" }}>
                My Profile
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                marginTop: hp(2),
                height: hp(3),
                justifyContent: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", color: "white" }}>
                Events Created
              </Text>
            </TouchableOpacity>

            {
              <View style={{ marginLeft: wp(1) }}>
                <View
                  style={{
                    borderBottomColor: "white",
                    borderBottomWidth: 0,
                    width: "90%",
                    height: hp(3),
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={search_icon}
                    style={{ width: 10, height: 10, marginLeft: 10 }}
                  />
                  <TextInput
                    style={{
                      height: "100%",
                      width: "100%",
                      paddingLeft: "5%",
                      color: "white",
                      fontSize: 12,
                      outline: "none",
                    }}
                    placeholder={"Search "}
                    onChangeText={(text) => {
                      setCreatedText(text);
                    }}
                  />
                </View>

                {event_created.length ? (
                  search_event_created().map((elem1, index) => {
                    return (
                      <>
                        <TouchableOpacity
                          style={{
                            marginTop: hp(3),
                            height: hp(3),
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                          onPress={() => {
                            navigation.push("EventHome", {
                              id: window.btoa(elem1._id),
                            });
                          }}
                        >
                          <Image
                            source={{
                              uri: elem1.event_image
                                ? elem1.event_image
                                : "https://cdn.dscovr.com/images/project-default.webp",
                            }}
                            style={{ width: 30, height: 30, borderRadius: 30 }}
                            resizeMode={"cover"}
                          />
                          <Text
                            style={{
                              fontWeight: "bold",
                              color: "white",
                              marginLeft: 10,
                              fontSize: 13,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {elem1.name}
                          </Text>
                        </TouchableOpacity>
                        {index + 1 >= event_created.length &&
                          !eventcreatefinish &&
                          event_created.length > 9 && (
                            <Text
                              onPress={() => {
                                getEventsCreated(create_page_limit + 1);
                                setcreate_page_limit(create_page_limit + 1);
                                // setEventsCreatedLimit(eventsCreatedLimit + 10)
                              }}
                              style={{
                                maxWidth: scale(200),
                                color: colors.white,
                                marginLeft: scale(0),
                                fontSize: 14,
                                fontWeight: "600",
                                marginTop: 20,
                                textAlign: "center",
                                alignSelf: "center",
                              }}
                            >
                              See More
                            </Text>
                          )}
                      </>
                    );
                  })
                ) : event_created.length === 0 && createdText.length > 0 ? (
                  <Text style={{ fontSize: 12, color: "white", marginTop: 10 }}>
                    No such event
                  </Text>
                ) : (
                  <></>
                )}
              </View>
            }

            {
              <View>
                <TouchableOpacity
                  style={{
                    marginTop: hp(2),
                    height: hp(3),
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold", color: "white" }}>
                    Events Joined
                  </Text>
                </TouchableOpacity>

                <View style={{ marginLeft: wp(1) }}>
                  <View
                    style={{
                      borderBottomColor: "white",
                      borderBottomWidth: 0,
                      width: "90%",
                      height: hp(3),
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={search_icon}
                      style={{ width: 10, height: 10, marginLeft: 10 }}
                    />
                    <TextInput
                      style={{
                        height: "100%",
                        width: "100%",
                        paddingLeft: "5%",
                        color: "white",
                        fontSize: 12,
                        outline: "none",
                      }}
                      placeholder={"Search "}
                      onChangeText={(text) => {
                        setJoinedText(text);
                      }}
                    />
                  </View>

                  {event_joined.length ? (
                    search_event_joined().map((elem1, index) => {
                      return (
                        <>
                          <TouchableOpacity
                            style={{
                              marginTop: hp(3),
                              height: hp(3),
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                            onPress={() => {
                              navigation.push("EventHome", {
                                id: window.btoa(elem1._id),
                              });
                            }}
                          >
                            <Image
                              source={{
                                uri: elem1.event_image
                                  ? elem1.event_image
                                  : "https://cdn.dscovr.com/images/project-default.webp",
                              }}
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: 30,
                              }}
                              resizeMode={"cover"}
                            />
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: "white",
                                marginLeft: 10,
                                fontSize: 13,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {elem1.name}
                            </Text>
                          </TouchableOpacity>

                          {index + 1 >= event_joined.length &&
                            !eventjoinfinish &&
                            event_joined.length > 9 && (
                              <Text
                                onPress={() => {
                                  getEventsJoined(join_page_limit + 1);
                                  setjoin_page_limit(join_page_limit + 1);
                                }}
                                style={{
                                  maxWidth: scale(200),
                                  color: colors.white,
                                  marginLeft: scale(0),
                                  fontSize: 14,
                                  fontWeight: "600",
                                  marginTop: 20,
                                  textAlign: "center",
                                  alignSelf: "center",
                                }}
                              >
                                See More
                              </Text>
                            )}
                        </>
                      );
                    })
                  ) : !event_joined.length && joinedText.length ? (
                    <Text
                      style={{ fontSize: 12, color: "white", marginTop: 10 }}
                    >
                      No such event
                    </Text>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            }

            {
              <View style={{ paddingBottom: 20 }}>
                <TouchableOpacity
                  style={{
                    marginTop: hp(2),
                    height: hp(3),
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold", color: "white" }}>
                    Events Closed
                  </Text>
                </TouchableOpacity>

                <View style={{ marginLeft: wp(1) }}>
                  <View
                    style={{
                      borderBottomColor: "white",
                      borderBottomWidth: 0,
                      width: "90%",
                      height: hp(3),
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={search_icon}
                      style={{ width: 10, height: 10, marginLeft: 10 }}
                    />
                    <TextInput
                      style={{
                        height: "100%",
                        width: "100%",
                        paddingLeft: "5%",
                        color: "white",
                        fontSize: 12,
                        outline: "none",
                      }}
                      placeholder={"Search "}
                      onChangeText={(text) => {
                        setClosedText(text);
                      }}
                    />
                  </View>
                  {event_closed.length ? (
                    search_event_closed().map((elem1, index) => {
                      return (
                        <>
                          <TouchableOpacity
                            style={{
                              marginTop: hp(3),
                              height: hp(3),
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                            onPress={() => {
                              navigation.push("EventHome", {
                                id: window.btoa(elem1._id),
                              });
                            }}
                          >
                            <Image
                              source={{
                                uri: elem1.event_image
                                  ? elem1.event_image
                                  : "https://cdn.dscovr.com/images/project-default.webp",
                              }}
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: 30,
                              }}
                              resizeMode={"cover"}
                            />
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: "white",
                                marginLeft: 10,
                                fontSize: 13,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {elem1.name}
                            </Text>
                          </TouchableOpacity>
                          {index + 1 >= event_closed.length &&
                            !eventclosedfinish &&
                            event_closed.length > 9 && (
                              <Text
                                onPress={() => {
                                  getEventsClosed(closed_page_limit + 1);
                                  setclosed_page_limit(closed_page_limit + 1);
                                  // setEventsCreatedLimit(eventsCreatedLimit + 10)
                                }}
                                style={{
                                  maxWidth: scale(200),
                                  color: colors.white,
                                  marginLeft: scale(0),
                                  fontSize: 14,
                                  fontWeight: "600",
                                  marginTop: 20,
                                  textAlign: "center",
                                  alignSelf: "center",
                                }}
                              >
                                See More
                              </Text>
                            )}
                        </>
                      );
                    })
                  ) : !event_closed.length && closedText.length ? (
                    <Text
                      style={{ fontSize: 12, color: "white", marginTop: 10 }}
                    >
                      No such event
                    </Text>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            }
          </View>
        )}

        {wp(100) <= 426 && (
          <Modal
            style={{ margin: 0, padding: 0 }}
            animationIn="slideInLeft"
            animationOut="slideOutLeft"
            isVisible={modalVisible}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <ScrollView style={{ maxHeight: hp(80) }}>
                  <TouchableOpacity
                    style={{ height: hp(3), justifyContent: "center" }}
                    onPress={() => {
                      navigation.push("Home");
                      setmodalVisible(!modalVisible);
                    }}
                  >
                    <Text style={{ fontWeight: "bold", color: "white" }}>
                      My Profile
                    </Text>
                  </TouchableOpacity>

                  {
                    <View>
                      <TouchableOpacity
                        style={{
                          marginTop: hp(2),
                          height: hp(3),
                          justifyContent: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "bold", color: "white" }}>
                          Events Created
                        </Text>
                      </TouchableOpacity>

                      <View style={{ marginLeft: wp(1) }}>
                        <View
                          style={{
                            borderBottomColor: "white",
                            borderBottomWidth: 0,
                            width: "90%",
                            height: hp(3),
                            flexDirection: "row",
                            alignItems: "center",
                            marginVertical: 5,
                          }}
                        >
                          <Image
                            source={search_icon}
                            style={{ width: 10, height: 10, marginLeft: 10 }}
                          />
                          <TextInput
                            style={{
                              height: "100%",
                              width: "100%",
                              paddingLeft: "5%",
                              color: "white",
                              fontSize: 12,
                              outline: "none",
                            }}
                            placeholder={"Search "}
                            onChangeText={(text) => {
                              getEventsCreated(1, text);
                              // setmodalVisible(false)
                            }}
                          />
                        </View>
                        {event_created.length ? (
                          search_event_created().map((elem1, index) => {
                            return (
                              <>
                                <TouchableOpacity
                                  style={{
                                    marginTop: hp(4),
                                    height: hp(3),
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                  onPress={() => {
                                    navigation.push("EventHome", {
                                      id: window.btoa(elem1._id),
                                    });
                                    setmodalVisible(false);
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: elem1.event_image
                                        ? elem1.event_image
                                        : "https://cdn.dscovr.com/images/project-default.webp",
                                    }}
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: 30,
                                    }}
                                    resizeMode={"cover"}
                                  />
                                  <Text
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      marginLeft: 10,
                                      fontSize: 13,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {elem1.name}
                                  </Text>
                                </TouchableOpacity>
                                {index + 1 >= event_created.length &&
                                  !eventcreatefinish &&
                                  event_created.length > 9 && (
                                    <Text
                                      onPress={() => {
                                        getEventsCreated(create_page_limit + 1);
                                        setcreate_page_limit(
                                          create_page_limit + 1
                                        );
                                        // setEventsCreatedLimit(eventsCreatedLimit + 10)
                                      }}
                                      style={{
                                        maxWidth: scale(200),
                                        color: colors.white,
                                        marginLeft: scale(0),
                                        fontSize: 14,
                                        fontWeight: "600",
                                        marginTop: 20,
                                        textAlign: "center",
                                        alignSelf: "center",
                                      }}
                                    >
                                      See More
                                    </Text>
                                  )}
                              </>
                            );
                          })
                        ) : !event_created.length && !createdText.length ? (
                          <Text
                            style={{
                              fontSize: 12,
                              color: "white",
                              marginTop: 10,
                            }}
                          >
                            No such event
                          </Text>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  }

                  <TouchableOpacity
                    style={{
                      marginTop: hp(4),
                      height: hp(3),
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "bold", color: "white" }}>
                      Events Joined
                    </Text>
                  </TouchableOpacity>

                  <View style={{ marginLeft: wp(1) }}>
                    <View
                      style={{
                        borderBottomColor: "white",
                        borderBottomWidth: 0,
                        width: "90%",
                        height: hp(3),
                        flexDirection: "row",
                        alignItems: "center",
                        marginVertical: 5,
                      }}
                    >
                      <Image
                        source={search_icon}
                        style={{ width: 10, height: 10, marginLeft: 10 }}
                      />
                      <TextInput
                        style={{
                          height: "100%",
                          width: "100%",
                          paddingLeft: "5%",
                          color: "white",
                          fontSize: 12,
                          outline: "none",
                        }}
                        placeholder={"Search "}
                        onChangeText={(text) => {
                          getEventsJoined(1, text);
                          // setmodalVisible(false)
                        }}
                      />
                    </View>

                    {event_joined.length ? (
                      search_event_joined().map((elem1, index) => {
                        return (
                          <>
                            <TouchableOpacity
                              style={{
                                marginTop: hp(4),
                                height: hp(3),
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                              onPress={() => {
                                navigation.push("EventHome", {
                                  id: window.btoa(elem1._id),
                                });
                                setmodalVisible(false);
                              }}
                            >
                              <Image
                                source={{
                                  uri: elem1.event_image
                                    ? elem1.event_image
                                    : "https://cdn.dscovr.com/images/project-default.webp",
                                }}
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: 30,
                                }}
                                resizeMode={"cover"}
                              />
                              <Text
                                style={{
                                  fontWeight: "bold",
                                  color: "white",
                                  marginLeft: 10,
                                  fontSize: 13,
                                }}
                              >
                                {elem1.name}
                              </Text>
                            </TouchableOpacity>

                            {index + 1 >= event_joined.length &&
                              !eventjoinfinish &&
                              event_joined.length > 9 && (
                                <Text
                                  onPress={() => {
                                    getEventsJoined(join_page_limit + 1);
                                    setjoin_page_limit(join_page_limit + 1);
                                  }}
                                  style={{
                                    maxWidth: scale(200),
                                    color: colors.white,
                                    marginLeft: scale(0),
                                    fontSize: 14,
                                    fontWeight: "600",
                                    marginTop: 20,
                                    textAlign: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  See More
                                </Text>
                              )}
                          </>
                        );
                      })
                    ) : !event_joined.length && !joinedText.length ? (
                      <Text
                        style={{ fontSize: 12, color: "white", marginTop: 10 }}
                      >
                        No such event
                      </Text>
                    ) : (
                      <></>
                    )}
                  </View>

                  <TouchableOpacity
                    style={{
                      marginTop: hp(4),
                      height: hp(3),
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "bold", color: "white" }}>
                      Events Closed
                    </Text>
                  </TouchableOpacity>

                  <View style={{ marginLeft: wp(1) }}>
                    <View
                      style={{
                        borderBottomColor: "white",
                        borderBottomWidth: 0,
                        width: "90%",
                        height: hp(3),
                        flexDirection: "row",
                        alignItems: "center",
                        marginVertical: 5,
                      }}
                    >
                      <Image
                        source={search_icon}
                        style={{ width: 10, height: 10, marginLeft: 10 }}
                      />
                      <TextInput
                        style={{
                          height: "100%",
                          width: "100%",
                          paddingLeft: "5%",
                          color: "white",
                          fontSize: 12,
                          outline: "none",
                        }}
                        placeholder={"Search "}
                        onChangeText={(text) => {
                          getEventsClosed(1, text);
                          // setmodalVisible(false)
                        }}
                      />
                    </View>
                    {event_closed.length ? (
                      search_event_closed().map((elem1, index) => {
                        return (
                          <>
                            <TouchableOpacity
                              style={{
                                marginTop: hp(4),
                                height: hp(3),
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                              onPress={() => {
                                navigation.push("EventHome", {
                                  id: window.btoa(elem1._id),
                                });
                                setmodalVisible(false);
                              }}
                            >
                              <Image
                                source={{
                                  uri: elem1.event_image
                                    ? elem1.event_image
                                    : "https://cdn.dscovr.com/images/project-default.webp",
                                }}
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: 30,
                                }}
                                resizeMode={"cover"}
                              />
                              <Text
                                style={{
                                  fontWeight: "bold",
                                  color: "white",
                                  marginLeft: 10,
                                  fontSize: 13,
                                }}
                              >
                                {elem1.name}
                              </Text>
                            </TouchableOpacity>
                            {index + 1 >= event_closed.length &&
                              !eventclosedfinish &&
                              event_closed.length > 9 && (
                                <Text
                                  onPress={() => {
                                    getEventsClosed(closed_page_limit + 1);
                                    setclosed_page_limit(closed_page_limit + 1);
                                    // setEventsCreatedLimit(eventsCreatedLimit + 10)
                                  }}
                                  style={{
                                    maxWidth: scale(200),
                                    color: colors.white,
                                    marginLeft: scale(0),
                                    fontSize: 14,
                                    fontWeight: "600",
                                    marginTop: 20,
                                    textAlign: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  See More
                                </Text>
                              )}
                          </>
                        );
                      })
                    ) : !event_closed.length && !closedText.length ? (
                      <Text
                        style={{ fontSize: 12, color: "white", marginTop: 10 }}
                      >
                        No such event
                      </Text>
                    ) : (
                      <></>
                    )}
                  </View>
                </ScrollView>
              </View>
              <TouchableOpacity
                style={{ position: "absolute", top: 10, left: 10 }}
                onPress={() => {
                  setmodalVisible(false);
                }}
              >
                <Image
                  source={arrow_left}
                  style={{ width: hp(3), height: hp(3) }}
                  resizeMode={"contain"}
                />
              </TouchableOpacity>
            </View>
          </Modal>
        )}

        {wp(100) <= 426 && (
          <Modal
            style={{ margin: 0, padding: 0 }}
            animationIn="slideInRight"
            animationOut="slideOutRight"
            isVisible={modalVisibleother}
          >
            <View style={styles.centeredRightView}>
              <View style={styles.modalRightView}>
                <ScrollView style={{ maxHeight: hp(80), paddingTop: 20 }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      source={{
                        uri: user?.userDetails?.profile_picture
                          ? user?.userDetails?.profile_picture
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
                      }}
                      style={{ width: 50, height: 50, borderRadius: 50 }}
                      resizeMode={"contain"}
                    />

                    {subscribed_till == "" && (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: 10,
                        }}
                      >
                        <Image
                          source={cloud_icon}
                          style={{ width: hp(2), height: hp(2) }}
                          resizeMode={"contain"}
                        />
                        <View style={{ alignItems: "center", marginLeft: 10 }}>
                          <Text style={{ color: "white", fontSize: 13 }}>
                            {total_storage}
                          </Text>
                          <Text
                            style={{
                              color: "white",
                              fontSize: 12,
                              marginTop: 4,
                            }}
                          >
                            Storage
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>

                  {subscribed_till != "" && (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View style={{ alignItems: "center", marginLeft: 10 }}>
                        <Text style={{ color: "white" }}>
                          You are Subscribed
                        </Text>
                        <Text
                          style={{ color: "white", fontSize: 12, marginTop: 4 }}
                        >
                          Valid Till {subscribed_till}
                        </Text>
                      </View>
                    </View>
                  )}

                  {plan_warning != "" && (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View style={{ alignItems: "center", marginLeft: 10 }}>
                        <Text style={{ color: "white" }}>
                          Subscription ended!
                        </Text>
                        <Text
                          style={{ color: "white", fontSize: 12, marginTop: 4 }}
                        >
                          {plan_warning}
                        </Text>
                      </View>
                    </View>
                  )}

                  {subscribed_till == "" && (
                    <TouchableOpacity
                      style={{
                        flexDirection: "row",
                        marginTop: hp(3),
                        marginLeft: wp(3),
                        alignItems: "center",
                        borderColor: "#FFFFFF",
                        borderWidth: 1,
                        borderRadius: 5,
                        paddingHorizontal: 9,
                        paddingVertical: 5,
                      }}
                      onPress={() => {
                        // alert("");
                        // if (document.cookie.indexOf(CookieName) != -1) {
                        //   console.log("Thank you for enabling Third-Party cookies");
                        // }

                        setplanselect(true);
                      }}
                    >
                      <Image
                        source={upgrade_icon}
                        style={{ width: 20, height: 20 }}
                      />
                      <Text style={{ marginLeft: 10, color: "white" }}>
                        Upgrade
                      </Text>
                    </TouchableOpacity>
                  )}

                  <Text
                    style={{ color: "white", marginVertical: 10, fontSize: 12 }}
                  >
                    Username : @ {user?.userDetails?.username}
                  </Text>
                  <Text
                    style={{ color: "white", marginVertical: 10, fontSize: 12 }}
                  >
                    Email : {user?.userDetails?.email}
                  </Text>

                  <TouchableOpacity
                    onPress={() => {
                      handleLogout();
                    }}
                    style={{ marginTop: 10, alignSelf: "center" }}
                  >
                    <Text style={{ color: "white" }}>Logout</Text>
                  </TouchableOpacity>
                </ScrollView>
              </View>
              <TouchableOpacity
                style={{ position: "absolute", top: 10, right: 10 }}
                onPress={() => {
                  setmodalVisibleother(false);
                }}
              >
                <Text style={{ color: "white", fontSize: 12 }}>close</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        )}

        <View
          style={{ width: wp(100) <= 426 ? "100%" : "85%", height: "100%" }}
        >
          {children}
        </View>
      </View>

      <Modal_new
        transparent={true}
        visible={modaladdpost}
        onRequestClose={() => {
          setmodaladdpost(!modaladdpost);
        }}
      >
        <View style={styles.centeredView_white}>
          <View style={styles.modalView_white}>
            <View style={{ alignItems: "center" }}>
              <View style={styles.header}>
                <TouchableOpacity onPress={() => setmodaladdpost(false)}>
                  <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                </TouchableOpacity>
              </View>
            </View>

            <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
              <CreatePostScreen
                get_upload_status={get_upload_status}
                uploading_goingon={setuploading_goingon}
                setmodaladdpost={setmodaladdpost}
                total_storage={total_storage}
                subscribed_till={subscribed_till}
                raw_available={raw_available}
                showupgradealert={showupgradealert}
              />
            </ScrollView>
          </View>
        </View>
      </Modal_new>

      <UpgradeSelect
        planselect={planselect}
        setplanselect={setplanselect}
        upgradealert={upgradealert}
        showupgradealert={showupgradealert}
      />
      <ToastContainer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
    overflowX: "hidden",
    maxHeight: hp(99.5),
  },
  modalView: {
    backgroundColor: "black",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderWidth: 1,
    borderRightColor: "white",
    padding: 20,
    paddingTop: 55,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: wp(55),
    minHeight: hp(100),
  },
  centeredView: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  centeredView_payment: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  centeredRightView: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalRightView: {
    backgroundColor: "black",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderWidth: 1,
    borderLeftColor: "white",
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: wp(55),
    minHeight: hp(100),
  },
  header: {
    width: "100%",
    height: wp(100) <= 426 ? 10 : 40,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  centeredView_white: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalView_white: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: wp(100) <= 820 ? wp(80) : wp(45),
    maxHeight: hp(95),
  },
  logoutMenu: {
    backgroundColor: "black",
  },
  modalView_payment: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: wp(100) <= 820 ? wp(80) : wp(20),
    maxHeight: wp(100) <= 820 ? "auto" : hp(95),
  },
  modalView_upgrade: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: wp(100) <= 820 ? wp(80) : wp(30),
    height: hp(20),
  },
});
