import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, ScrollView, Image, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { scale, colors, device, wp, hp } from '../utils';
import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import { initializeApp } from 'firebase/app';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { logo, app_store, play_store, login_img, down_arrow } from '../assets'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reduxStore/store';
import { NETWORK_URL, country_code } from '../utils/config';
import { storeToken, userDetails } from '../reduxStore/actions';
import { CommonActions } from '@react-navigation/native';
import LinearGradient from 'react-native-web-linear-gradient';
import SelectPicker from 'react-native-form-select-picker';
import { Picker } from '@react-native-picker/picker';

WebBrowser.maybeCompleteAuthSession();

const Tab = createMaterialTopTabNavigator();

initializeApp({
    apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
    authDomain: "farmhomes-bcefb.firebaseapp.com",
    projectId: "farmhomes-bcefb",
    storageBucket: "farmhomes-bcefb.appspot.com",
    messagingSenderId: "718737440796",
    appId: "1:718737440796:web:395567042f5eee01329fd9",
    measurementId: "G-NGCNHJQGG5"
});

export default function ForgetPassword({ navigation, route }: any) {

    const [mobile, setmobile] = useState<any>("")
    const [processing, setProcessing] = useState(false);
    const [country, setcountry] = useState<any>("+" + country_code[0].code)
    const [message, setMessage] = useState<any>(null)
    const [error, setError] = useState<String | null>(null);

    const handleVerify = (values: any) => {
        axios.post(`${NETWORK_URL}/passwordApi/otp_send`, {
            telephone: values.mobile,
            countryCode: country
        })
            .then((response: any) => {
                console.log("OTP Details>>>", response.data);
                // alert(response.data.message)

                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [
                            {
                                name: 'Otp_forgot_send', params: {
                                    telephone: window.btoa(values.mobile),
                                    countryCode: country
                                }
                            },
                        ],
                    })
                );

            })
            .catch(function (error) {
                setError('Invalid Otp, Please try again')
                console.log(error);

                alert(error.response.data.message);

            });
    }

    return (
        <View style={styles.container}>

            <View style={{ backgroundColor: "white", elevation: 5, width: wp(100) <= 768 ? wp(90) : "25%", alignSelf: 'center', marginTop: hp(10), paddingBottom: hp(1), borderRadius: wp(2) }}>

                <Image source={logo} style={{ position: "absolute", alignSelf: "center", width: 80, height: 80, borderRadius: 80, top: -40 }} resizeMode={"contain"} />

                <Text style={{ color: "black", marginTop: hp(5.5), marginLeft: wp(2.5), fontSize: 20, fontWeight: "bold", textDecorationLine: 'underline' }}>Verification</Text>

                <View style={{ flexDirection: "row", marginHorizontal: wp(2.5), alignItems: "center", justifyContent: "space-between" }}>
                    <Text style={{ marginTop: 10, fontSize: 12 }}>Please enter your Mobile number to receive the Instructions to reset your password</Text>
                </View>

                <View>

                    <View style={{ width: "90%", marginLeft: "5%", flexDirection: "row", alignItems: "center", marginTop: hp(4) }}>
                        {wp(100) > 425 && <View style={{ width: "20%" }}>
                            <Picker
                                style={{ borderRadius: wp(0.5), paddingHorizontal: 2, height: 30, width: "100%", borderWidth: 1, borderColor: "#FF5C5B",color:"black" }}
                                selectedValue={country}
                                onValueChange={(itemValue, itemIndex) =>
                                    setcountry(itemValue)
                                }>
                                {
                                    country_code.map((col, colIndex) => {
                                        return <Picker.Item label={"+" + col.code} value={"+" + col.code} />
                                    })
                                }
                            </Picker>
                        </View>}
                        {wp(100) <= 425 && <View style={{ width: "20%", height : "100%" }}>
                            <SelectPicker
                                style={{ borderRadius: wp(0.5), paddingHorizontal: 10, width: "100%" , height : "100%", borderWidth: 0, backgroundColor: "#FF5C5B" }}
                                onSelectedStyle={{ color: "white", fontSize: "75%" }}
                                selected={country}
                                onValueChange={(itemValue, itemIndex) =>
                                    setcountry(itemValue)
                                }
                            >

                                {
                                    country_code.map((col, colIndex) => {
                                        return <SelectPicker.Item label={"+" + col.code} value={"+" + col.code} />
                                    })
                                }

                            </SelectPicker>
                            <Image source={down_arrow} style={{ position: "absolute", right: 10, top: "35%", width: 10, height: 10 }} resizeMode={"contain"} />
                        </View>}
                        <TextInput
                            style={{ width: "78%", marginLeft: "2%", borderRadius: 10, borderWidth: 0.2, padding: 15, borderColor: "#c9c9c9", height : hp(5) }}
                            placeholder={"Mobile Number"}
                            value={mobile}
                            onChangeText={(text) => { setmobile(text) }}
                        />
                    </View>

                    <TouchableOpacity
                        style={{ backgroundColor: colors.primary, width: "90%", marginLeft: "5%", marginTop: hp(4), borderRadius: wp(2) }}
                        onPress={() => {

                            if (processing) return true;

                            handleVerify({ mobile: mobile });

                        }}>
                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", borderRadius: 20 }}>
                            {processing ? <ActivityIndicator size="small" color="#ffffff" style={{ marginVertical: hp(2) }} /> : <Text style={{ alignSelf: "center", marginVertical: hp(2), fontSize: 18, color: "white" }}>Send</Text>}
                        </LinearGradient>
                    </TouchableOpacity>

                </View>

            </View>

            <Text style={{ color: "white", alignSelf: "center", marginTop: hp(3), fontSize: 18 }}>Get the app</Text>

            <View style={styles.SocialMediaButtonHolder}>
                <TouchableOpacity onPress={() => { }}>
                    <Image style={styles.storeMediaButton} source={app_store} resizeMode={"contain"} />
                </TouchableOpacity>
                <View style={{ height: scale(8), width: 1, backgroundColor: "#c9c9c9" }}></View>
                <TouchableOpacity onPress={() => { }}>
                    <Image style={styles.storeMediaButton} source={play_store} resizeMode={"contain"} />
                </TouchableOpacity>
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        overflowX: "hidden"
    },
    orHolder: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 15
    },
    orText: {
        color: colors.secondary,
        backgroundColor: '#fff',
        fontSize: scale(14)
    },
    SocialMediaButtonHolder: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: hp(3),
        alignSelf: "center"
    },
    socislMediaButton: {
        height: wp(100) <= 768 ? wp(6) : wp(1.5),
        width: wp(100) <= 768 ? wp(6) : wp(1.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : wp(2)
    },
    storeMediaButton: {
        height: wp(100) <= 768 ? wp(30) : wp(3.5),
        width: wp(100) <= 768 ? wp(30) : wp(8.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : scale(7)
    }
});