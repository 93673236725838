import {scale}  from "./scale";
const shadow = {
    shadowColor: '#ddd',
    shadowOpacity: 0.8,
    elevation: 1,
    marginBottom: scale(10),
    borderStyle: 'solid',
    borderWidth: scale(1),
    borderColor: '#eee',
    shadowOffset: {
        width: 0,
        height: 2
      },
}

export default shadow;