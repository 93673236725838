import { View, Text, TouchableOpacity, Image, Alert, Modal as Modal_new, StyleSheet, ScrollView } from 'react-native';
import React, { useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { scale, wp, colors, hp } from '../utils';
import { AntDesign } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { ProgressBar, TextInput } from 'react-native-paper';
import InputAutoSuggest from './InputAutoSuggest';
import { NETWORK_URL } from '../utils/config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reduxStore/store';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import * as VideoThumbnails from 'expo-video-thumbnails';
import LinearGradient from 'react-native-web-linear-gradient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-native-modal';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51L1LlBIPu7IYyJxOMn5jwbhjM2XNVKHr5TUjn6SnbONgb0CP8m2kHYs81L2LM6323MYI3MFsndI76DLPVecsGGhq00atSH9lgb');

function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

const UpgradeSelect = ({ upgradealert, showupgradealert, setplanselect, planselect }) => {

    const [planlist, setplanlist] = useState<any>([]);

    const [planselect_json, setplanselect_json] = useState<any>(0);

    const [modalpaymentgateway, setmodalpaymentgateway] = useState<any>(false);

    const [options, setoptions] = useState<any>({});

    const user = useSelector((state: RootState) => state.userReducer);
    const dispatch = useDispatch();

    const CheckoutForm = () => {

        const stripe = useStripe();

        const elements = useElements();

        const handleSubmit = async (event) => {
            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            var url = new URL(window.location.href);

            // If your expected result is "http://foo.bar/?x=1&y=2&x=42"
            url.searchParams.append('upgrade', "true");

            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: url.href
                }
            });

            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                alert(result.error.message);
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.

            }
        };

        return (
            <form style={{ alignItems: "center", justifyContent: "center" }} onSubmit={handleSubmit}>
                <PaymentElement />
                <button style={{ height: 30, width: 80, borderRadius: 20, color: "white", backgroundColor: "#FF5C5B", marginTop: 20, alignSelf: "center", cursor: "pointer" }}>Pay</button>
            </form>
        );
    };

    useFocusEffect(
        React.useCallback(() => {


            // alert(route?.params?.payment_intent_client_secret);

            var url_string = window.location.href; //window.location.href
            var url = new URL(url_string);

            // alert(url.searchParams.get("upgrade"));

            if (url.searchParams.get("upgrade")) {
                axios.post(`${NETWORK_URL}/subscriptionApi/update`,
                    {
                        payment_id: url.searchParams.get("payment_intent_client_secret")
                    }
                    , {
                        headers: {
                            "x-access-token": user.auth_token
                        }
                    })
                    .then((response) => {

                        alert("Subscription Completed");

                        // notify("Subscription Completed");

                        window.location.href = removeParam("payment_intent", removeParam("payment_intent_client_secret", removeParam("upgrade", window.location.href)));

                    })
                    .catch(function (error: any) {
                        alert(JSON.stringify(error.response.data))
                    });
            }

            axios.get(`${NETWORK_URL}/planApi`, {
                headers: {
                    "x-access-token": user.auth_token
                }
            })
                .then((response) => {
    
                    setplanlist(response.data);
    
    
                })
                .catch(function (error: any) {
    
                    // alert(error.response.data.message);
    
                });


        }, [])
    );

    return (
        <SafeAreaView style={{ margin: wp(100) <= 426 ? 5 : 10 }}>

            <Modal_new
                animationType="slide"
                transparent={true}
                visible={upgradealert}
                onRequestClose={() => {
                    showupgradealert(!upgradealert);
                }}>
                <View style={styles.centeredView_payment}>
                    <View style={styles.modalView_upgrade}>

                        <View style={{ alignItems: 'center' }}>
                            <View style={styles.header}>
                                <TouchableOpacity onPress={() => showupgradealert(false)}>
                                    <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View style={{ height: wp(100) <= 820 ? hp(40) : hp(50), width: "100%" }}>

                            <Text>File selected exceded available quota!</Text>

                            <TouchableOpacity
                                onPress={() => {

                                    showupgradealert(false)

                                    setTimeout(function () {
                                        setplanselect(true);
                                    }, 200);


                                }} style={{ borderRadius: 30, marginLeft: 10, alignSelf: "flex-end", marginTop: hp(4) }}>
                                <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", height: "100%", borderRadius: 30, alignItems: "center", justifyContent: "center" }}>
                                    <Text style={{ color: "white", alignSelf: "center", marginHorizontal: 10, marginVertical: 5 }}>Upgrade</Text>
                                </LinearGradient>

                            </TouchableOpacity>

                        </View>

                    </View>

                </View>
            </Modal_new>

            <Modal
                transparent={true}
                visible={planselect}
                onRequestClose={() => {
                    setplanselect(!planselect);
                }}>
                <View style={styles.centeredView_white}>

                    <View style={styles.modalView_payment}>

                        <View style={{ alignItems: 'center' }}>
                            <View style={styles.header}>
                                <TouchableOpacity onPress={() => setplanselect(false)}>
                                    <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
                            <Text style={{ alignSelf: "center", fontWeight: "bold" }}>Select Plan</Text>
                            {planlist.map((elem1, key) => {

                                return (
                                    <TouchableOpacity style={{ marginVertical: hp(2), height: hp(3), alignItems: "center", flexDirection: "row", borderRadius: 10, borderWidth: 1, borderColor: "#20CCD6", paddingVertical: 20, paddingHorizontal: 5, justifyContent: 'space-between' }}
                                        onPress={() => {

                                            setplanselect_json(elem1);

                                            axios.post(`${NETWORK_URL}/subscriptionApi/create_subscription`,
                                                {

                                                    product: {
                                                        plan_id: elem1._id
                                                    }

                                                }
                                                ,
                                                {
                                                    headers: {
                                                        "x-access-token": user.auth_token
                                                    }
                                                })
                                                .then((response) => {

                                                    setplanselect(false);

                                                    setTimeout(function () {
                                                        setmodalpaymentgateway(true);
                                                    }, 200);

                                                    setoptions({
                                                        // passing the client secret obtained from the server
                                                        clientSecret: response.data.id,
                                                    });

                                                })
                                                .catch(function (error: any) {

                                                    alert(error.response.data.message);

                                                });

                                        }}>
                                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                                            <Image source={{ uri: "https://cdn.iconscout.com/icon/free/png-256/subscription-1742827-1479747.png" }} style={{ width: 30, height: 30, borderRadius: 30 }} resizeMode={"contain"} />
                                            <View style={{ marginLeft: 10 }}>
                                                <Text style={{ fontWeight: "bold", color: "#FF5C5B", fontSize: 14 }}>{elem1.name}</Text>
                                                <Text style={{ fontWeight: "bold", color: "#FF5C5B", marginTop: 5, fontSize: 10 }}>({elem1.maxStorage == - 1 ? "Unlimited" : elem1.maxStorage})</Text>
                                            </View>
                                        </View>
                                        <Text style={{ fontWeight: "bold", color: "#20CCD6", marginLeft: 10, fontSize: 13 }}>$ {elem1.price}</Text>
                                    </TouchableOpacity>
                                )

                            })}

                            <Text style={{ marginBottom: 5, fontSize: 12 }}>Note : Please enable third party cookies for payment to work!</Text>
                        </ScrollView>

                    </View>

                </View>
            </Modal>



            <Modal_new
                animationType="slide"
                transparent={true}
                visible={modalpaymentgateway}
                onRequestClose={() => {
                    setmodalpaymentgateway(!modalpaymentgateway);
                }}>
                <View style={styles.centeredView_payment}>
                    <View style={styles.modalView_payment}>

                        <View style={{ alignItems: 'center' }}>
                            <View style={styles.header}>
                                <TouchableOpacity onPress={() => setmodalpaymentgateway(false)}>
                                    <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View style={{ height: wp(100) <= 820 ? hp(40) : hp(50), width: "100%" }}>

                            <View style={{ marginVertical: hp(2), height: hp(3), alignItems: "center", flexDirection: "row", borderRadius: 10, borderWidth: 1, borderColor: "#20CCD6", paddingVertical: 20, paddingHorizontal: 5, justifyContent: 'space-between' }}>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <Image source={{ uri: "https://cdn.iconscout.com/icon/free/png-256/subscription-1742827-1479747.png" }} style={{ width: 30, height: 30, borderRadius: 30 }} resizeMode={"contain"} />
                                    <View style={{ marginLeft: 10 }}>
                                        <Text style={{ fontWeight: "bold", color: "#FF5C5B", fontSize: 14 }}>{planselect_json.name}</Text>
                                        <Text style={{ fontWeight: "bold", color: "#FF5C5B", marginTop: 5, fontSize: 10 }}>({planselect_json.maxStorage == - 1 ? "Unlimited" : planselect_json.maxStorage})</Text>
                                    </View>
                                </View>
                                <Text style={{ fontWeight: "bold", color: "#20CCD6", marginLeft: 10, fontSize: 13 }}>{planselect_json.price}</Text>
                            </View>

                            {options?.clientSecret && <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm />
                            </Elements>}

                        </View>

                    </View>

                </View>
            </Modal_new>

        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        overflowX: "hidden",
        maxHeight: hp(99.5)
    },
    modalView: {
        backgroundColor: 'black',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderWidth: 1,
        borderRightColor: "white",
        padding: 20,
        paddingTop: 55,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: wp(55),
        minHeight: hp(100)
    },
    centeredView: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    centeredView_payment: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    centeredRightView: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    modalRightView: {
        backgroundColor: 'black',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderWidth: 1,
        borderLeftColor: "white",
        padding: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: wp(55),
        minHeight: hp(100)
    },
    header: {
        width: '100%',
        height: wp(100) <= 426 ? 10 : 40,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    centeredView_white: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    modalView_white: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: wp(100) <= 820 ? wp(80) : wp(45),
        maxHeight: hp(95)
    },
    logoutMenu: {
        backgroundColor: "black"
    },
    modalView_payment: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: wp(100) <= 820 ? wp(80) : wp(20),
        maxHeight: wp(100) <= 820 ? "auto" : hp(95)
    },
    modalView_upgrade: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: wp(100) <= 820 ? wp(80) : wp(30),
        height: hp(20)
    },
});



export default UpgradeSelect;