import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  ImageBackground,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { scale, colors, device, wp, hp } from "../utils";
import * as WebBrowser from "expo-web-browser";
import { ResponseType } from "expo-auth-session";
import * as Google from "expo-auth-session/providers/google";
import { initializeApp } from "firebase/app";
import * as Facebook from "expo-auth-session/providers/facebook";
import {
  logo,
  app_store,
  play_store,
  login_img_1,
  down_arrow,
  hide,
  view,
} from "../assets";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reduxStore/store";
import { NETWORK_URL, country_code } from "../utils/config";
import { storeToken, userDetails } from "../reduxStore/actions";
import { CommonActions } from "@react-navigation/native";
import LinearGradient from "react-native-web-linear-gradient";
import { Popover } from "react-native-popable";
import SelectPicker from "react-native-form-select-picker";
import { Picker } from "@react-native-picker/picker";

WebBrowser.maybeCompleteAuthSession();

const Tab = createMaterialTopTabNavigator();

initializeApp({
  apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
  authDomain: "farmhomes-bcefb.firebaseapp.com",
  projectId: "farmhomes-bcefb",
  storageBucket: "farmhomes-bcefb.appspot.com",
  messagingSenderId: "718737440796",
  appId: "1:718737440796:web:395567042f5eee01329fd9",
  measurementId: "G-NGCNHJQGG5",
});

export default function Landing_web({ navigation }: any) {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<String | null>(null);
  const [message, setMessage] = useState<any>(null);
  const user = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch();
  const [login_text, setlogin_text] = useState<any>("");
  const [password_text, setpassword_text] = useState<any>("");
  const [loginWith, setLoginWith] = useState<any>("mobile");
  const [popupVisible, setPopupVisible] = useState(false);
  const [country, setcountry] = useState<any>("+" + country_code[0].code);
  const [show_password, setshow_password] = useState<any>(false);

  const handleLogin = (values: any) => {
    setProcessing(true);
    setError(null);

    axios
      .post(`${NETWORK_URL}/authApi/login`, {
        username: values.login,
        password: values.password,
        countryCode: country,
      })
      .then((response) => {
        if (response.status) {
          setProcessing(false);

          dispatch(storeToken(response.data.token));
          dispatch(userDetails(response.data.userDetails));

          // alert(JSON.stringify(response));

          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Home" }],
            })
          );
        }
      })
      .catch(function (error: any) {
        console.log(error.response);
        setError(error.response.data.message);

        // alert(JSON.stringify(error));

        // alert(error.response.status);

        if (error.response.status == 403) {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [
                {
                  name: "Otp_web",
                  params: {
                    telephone: window.btoa(
                      error.response.data.userDetails.mobileNumber
                    ),
                    countryCode: error.response.data.userDetails.countryCode,
                  },
                },
              ],
            })
          );
        }

        setProcessing(false);
      });
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          backgroundColor: "white",
          elevation: 5,
          width: wp(100) <= 768 ? wp(90) : "25%",
          height: hp(45),
          alignSelf: wp(100) <= 768 ? "center" : "auto",
          marginLeft: wp(100) <= 768 ? 0 : wp(45),
          marginTop: hp(15),
          borderRadius: wp(2),
        }}
      >
        <Image
          source={logo}
          style={{
            position: "absolute",
            alignSelf: "center",
            width: 80,
            height: 80,
            borderRadius: 80,
            top: wp(100) <= 425 ? -60 : -40,
          }}
          resizeMode={"contain"}
        />

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: hp(6.5),
          }}
        >
          <Text
            style={{
              color: "black",
              marginLeft: "5%",
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            Log in with {loginWith == "username" ? "Username" : "Mobile No"}
          </Text>

          <TouchableOpacity
            onPress={() => {
              setPopupVisible(true);
            }}
          >
            <Image
              source={{
                uri: "https://icons.veryicon.com/png/o/internet--web/prejudice/down-arrow-5.png",
              }}
              style={{ height: 15, width: 15, marginLeft: 10 }}
              resizeMode={"contain"}
            />
          </TouchableOpacity>

          <Popover
            position={wp(100) <= 425 ? "left" : "bottom"}
            visible={popupVisible}
            wrapperStyle={{ zIndex: 2 }}
            style={{
              zIndex: 2,
              width: wp(100) >= 425 ? wp(10) : wp(25),
              position: "absolute",
              top: wp(100) <= 425 ? 0 : hp(2),
              left: wp(100) <= 425 ? "30%" : "25%",
              alignSelf: "flex-start",
            }}
          >
            <TouchableOpacity
              style={{
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                flexDirection: "row",
                borderWidth: 1,
              }}
              onPress={() => {
                setLoginWith("mobile");
                setPopupVisible(false);
                setlogin_text("");
              }}
            >
              <Text>Mobile</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                flexDirection: "row",
                borderWidth: 1,
              }}
              onPress={() => {
                setLoginWith("username");
                setPopupVisible(false);
                setlogin_text("");
              }}
            >
              <Text>Username</Text>
            </TouchableOpacity>
          </Popover>
        </View>

        <View style={{ zIndex: -99999, marginTop: hp(2) }}>
          {loginWith == "username" ? (
            <>
              <TextInput
                style={{
                  width: "90%",
                  marginLeft: "5%",
                  borderRadius: 10,
                  borderWidth: 0.2,
                  padding: 15,
                  borderColor: "#c9c9c9",
                  height: hp(5),
                }}
                placeholder={"@User name"}
                value={login_text}
                onChangeText={(text) => {
                  setlogin_text(text);
                }}
              />
            </>
          ) : (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "5%",
              }}
            >
              {wp(100) > 425 && (
                <View style={{ width: "20%", height: "100%" }}>
                  <Picker
                    style={{
                      borderRadius: wp(0.5),
                      paddingHorizontal: 2,
                      height: "100%",
                      width: "100%",
                      borderWidth: 1,
                      borderColor: "#FF5C5B",
                      color: "black",
                    }}
                    selectedValue={country}
                    onValueChange={(itemValue, itemIndex) =>
                      setcountry(itemValue)
                    }
                  >
                    {country_code.map((col, colIndex) => {
                      return (
                        <Picker.Item
                          label={"+" + col.code}
                          value={"+" + col.code}
                        />
                      );
                    })}
                  </Picker>
                </View>
              )}
              {wp(100) <= 425 && (
                <View style={{ width: "20%" }}>
                  <SelectPicker
                    style={{
                      borderRadius: wp(0.5),
                      paddingHorizontal: 10,
                      width: "100%",
                      borderWidth: 0,
                      backgroundColor: "#FF5C5B",
                    }}
                    onSelectedStyle={{ color: "white", fontSize: "75%" }}
                    selected={country}
                    onValueChange={(itemValue, itemIndex) =>
                      setcountry(itemValue)
                    }
                  >
                    {country_code.map((col, colIndex) => {
                      return (
                        <SelectPicker.Item
                          label={"+" + col.code}
                          value={"+" + col.code}
                        />
                      );
                    })}
                  </SelectPicker>
                  <Image
                    source={down_arrow}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: "35%",
                      width: 10,
                      height: 10,
                    }}
                    resizeMode={"contain"}
                  />
                </View>
              )}
              <TextInput
                style={{
                  width: "70%",
                  marginLeft: "2%",
                  borderRadius: 10,
                  borderWidth: 0.2,
                  padding: 15,
                  borderColor: "#c9c9c9",
                  height: hp(5),
                }}
                placeholder={"@Mobile"}
                value={login_text}
                onChangeText={(text) => {
                  setlogin_text(text);
                }}
              />
            </View>
          )}

          <View
            style={{ width: "90%", justifyContent: "center", marginLeft: "5%" }}
          >
            <TextInput
              style={{
                width: "100%",
                borderRadius: 10,
                borderWidth: 0.2,
                padding: 15,
                marginTop: hp(3),
                borderColor: "#c9c9c9",
                height: hp(5),
              }}
              placeholder={"Password"}
              value={password_text}
              secureTextEntry={!show_password}
              onChangeText={(text) => {
                setpassword_text(text);
              }}
            />

            <TouchableOpacity
              style={{ position: "absolute", right: 10, top: "53%" }}
              onPress={() => {
                setshow_password(!show_password);
              }}
            >
              <Image
                source={show_password ? hide : view}
                style={{ width: 20, height: 20 }}
                resizeMode={"contain"}
              />
            </TouchableOpacity>
          </View>

          {error !== null && (
            <Text style={{ alignSelf: "center", color: "red" }}>{error}</Text>
          )}

          <TouchableOpacity
            style={{
              backgroundColor: colors.primary,
              width: "90%",
              marginLeft: "5%",
              marginTop: hp(4),
              borderRadius: wp(2),
            }}
            onPress={() => {
              handleLogin({ login: login_text, password: password_text });
            }}
          >
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={["#FF5C5B", "#20CCD6"]}
              style={{ width: "100%" }}
            >
              <Text
                style={{
                  alignSelf: "center",
                  marginVertical: hp(2),
                  fontSize: 18,
                  color: "white",
                }}
              >
                Log In
              </Text>
            </LinearGradient>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              navigation.push("ForgetPassword");
            }}
          >
            <Text
              style={{
                alignSelf: "center",
                marginVertical: hp(1.5),
                fontSize: 12,
                color: "black",
              }}
            >
              Forgot Password
            </Text>
          </TouchableOpacity>

          {/* <TouchableOpacity style={{ marginTop: hp(2.5), alignSelf: "center" }}>
              <Text style={{ color: colors.primary }}>Forgot Password?</Text>
            </TouchableOpacity> */}
        </View>
      </View>

      <View
        style={{
          backgroundColor: "white",
          elevation: 5,
          width: wp(100) <= 768 ? wp(90) : "25%",
          height: hp(10),
          alignSelf: wp(100) <= 768 ? "center" : "auto",
          marginLeft: wp(100) <= 768 ? 0 : wp(45),
          marginTop: hp(2),
          borderRadius: wp(2),
        }}
      >
        <Text style={{ alignSelf: "center", marginTop: hp(3.8), fontSize: 16 }}>
          {" "}
          Don't have an account ?
          <TouchableOpacity
            style={{ marginLeft: scale(2) }}
            onPress={() => {
              navigation.navigate("Signup");
            }}
          >
            <Text style={{ color: "black", fontWeight: "bold" }}>Sign Up</Text>
          </TouchableOpacity>
        </Text>
      </View>

      <View
        style={{
          alignSelf: wp(100) <= 768 ? "center" : "auto",
          marginLeft: wp(100) <= 768 ? 0 : wp(45),
          width: "25%",
        }}
      >
        <Text
          style={{
            color: "white",
            marginTop: hp(3),
            fontSize: 18,
            alignItems: "center",
            width: "100%",
            textAlign: "center",
          }}
        >
          Get the app
        </Text>

        <View style={styles.SocialMediaButtonHolder}>
          <TouchableOpacity onPress={() => {
            window.open("https://apps.apple.com/in/app/snapyfi/id6472412812");
          }}>
            <Image
              style={styles.storeMediaButton}
              source={app_store}
              resizeMode={"contain"}
            />
          </TouchableOpacity>
          <View
            style={{ height: scale(8), width: 1, backgroundColor: "#c9c9c9" }}
          ></View>
          <TouchableOpacity onPress={() => {
            window.open("https://play.google.com/store/apps/details?id=com.tru.snapify");
          }}>
            <Image
              style={styles.storeMediaButton}
              source={play_store}
              resizeMode={"contain"}
            />
          </TouchableOpacity>
        </View>
      </View>

      {wp(100) > 768 && (
        <Image
          source={login_img_1}
          style={{
            position: "absolute",
            left: wp(15.5),
            height: hp(80),
            width: wp(30),
            marginTop: hp(8),
          }}
          resizeMode="contain"
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
    overflowX: "hidden",
  },
  orHolder: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 15,
  },
  orText: {
    color: colors.secondary,
    backgroundColor: "#fff",
    fontSize: scale(14),
  },
  SocialMediaButtonHolder: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: hp(3),
  },
  socislMediaButton: {
    height: wp(100) <= 768 ? wp(6) : wp(1.5),
    width: wp(100) <= 768 ? wp(6) : wp(1.5),
    marginHorizontal: wp(100) <= 768 ? wp(10) : wp(2),
  },
  storeMediaButton: {
    height: wp(100) <= 768 ? wp(30) : wp(3.5),
    width: wp(100) <= 768 ? wp(30) : wp(8.5),
    marginHorizontal: wp(100) <= 768 ? wp(10) : scale(7),
  },
});
