import axios from 'axios';
import {NETWORK_URL} from '../utils/config';
import {Dispatch} from 'redux';

export const STORE_TOKEN = 'STORE_TOKEN';
export const STORE_USER_DETAILS = 'STORE_USER_DETAILS';
export const FIRST_VISIT = 'FIRST_VISIT';
export const USER_LOGOUT = 'USER_LOGOUT';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const MOBILE_VERIFIED = 'MOBILE_VERIFIED';
export const STORE_DESIGN = 'STORE_DESIGN';
export const STORE_MATERIAL = 'STORE_MATERIAL';
export const STORE_IMAGES = 'STORE_IMAGES';
export const CLEAR_DESIGN = "CLEAR_DESIGN";
export const STORE_TOP_ID = "STORE_TOP_ID";
export const STORE_TOP_OBJ = "STORE_TOP_OBJ";

export const FILE_PATH = "FILE_PATH";



export const storeToken = (token: any) => (dispatch: Dispatch) => {
    dispatch({
        type: STORE_TOKEN,
        payload: token,
    });
}

export const userLogout = () => (dispatch: Dispatch) => {
    dispatch({
        type: USER_LOGOUT,
    });
}

export const firstVisit = (data: boolean) => (dispatch: Dispatch) => {
    dispatch({
        type: FIRST_VISIT,
        payload: data,
    });
}

export const userDetails = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        type: STORE_USER_DETAILS,
        payload: data,
    });
}

export const storeDesign = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        type: STORE_DESIGN,
        payload: data,
    })
}
export const storeTopId = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        type: STORE_TOP_ID,
        payload: data,
    })
}
export const storeTopObj = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        type: STORE_TOP_OBJ,
        payload: data,
    })
}

export const storeMaterial = (data:any) => (dispatch: Dispatch) => {
    dispatch({
        type: STORE_MATERIAL,
        payload: data,
    })
}

export const storeImages = (data:any) => (dispatch: Dispatch) => {
    dispatch({
        type: STORE_IMAGES,
        payload: data,
    })
}

export const clearDesign = () => (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_DESIGN,
    })
}
export const filePath = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        type: FILE_PATH,
        payload: data,
    })
}

