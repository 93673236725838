import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, ScrollView, Image, ImageBackground, TextInput, TouchableOpacity, Button, Modal } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { scale, colors, device, wp, hp } from '../utils';
import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import { initializeApp } from 'firebase/app';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { header_logo, search_icon, cloud_icon, upgrade_icon, upload_icon } from '../assets'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reduxStore/store';
import { NETWORK_URL } from '../utils/config';
import { storeToken, userDetails } from '../reduxStore/actions';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import LinearGradient from 'react-native-web-linear-gradient';
import Navigation from './Navigation';
import PhotoGrid from 'react-native-thumbnail-grid';
import ReactHlsPlayer from 'react-hls-player';
import Hoverable from "./Hoverable";
import { Video } from 'expo-av';

WebBrowser.maybeCompleteAuthSession();

initializeApp({
    apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
    authDomain: "farmhomes-bcefb.firebaseapp.com",
    projectId: "farmhomes-bcefb",
    storageBucket: "farmhomes-bcefb.appspot.com",
    messagingSenderId: "718737440796",
    appId: "1:718737440796:web:395567042f5eee01329fd9",
    measurementId: "G-NGCNHJQGG5"
});

function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
}

function download_files(files) {
    function download_next(i) {
        if (i >= files.length) {
            return;
        }
        var a = document.createElement('a');
        a.href = files[i];
        a.target = '_parent';
        // Use a.download if available, it prevents plugins from opening.
        // if ('download' in a) {
        //     a.download = "sample.jpeg";
        // }
        // Add a to the doc for click to work.
        (document.body || document.documentElement).appendChild(a);
        if (a.click) {
            a.click(); // The click method is supported by most browsers.
        } else {
            // $(a).click(); // Backup using jquery
        }
        // Delete the temporary link.
        //   a.parentNode.removeChild(a);
        // Download the next file with a small timeout. The timeout is necessary
        // for IE, which will otherwise only download the first file.
        setTimeout(function () {
            download_next(i + 1);
        }, 2000);
    }
    // Initiate the first download.
    download_next(0);
}

export default function Home({ navigation }: any) {

    const user = useSelector((state: RootState) => state.userReducer);
    const dispatch = useDispatch();
    const [posts, setposts] = useState<any>([]);
    const [type_media, settype_media] = useState<any>("image");
    const [modalshowimage, setmodalshowimage] = useState<any>(false);
    const [image_show, setimage_show] = useState<any>({});

    const [selected_image, setselected_image] = useState<any>([]);
    const [selected_image_url, setselected_image_url] = useState<any>([]);

    const [showimage, setshowimage] = useState<any>({});

    const [subscribed_till, setsubscribed_till] = useState<any>("");

    const [total_storage, settotal_storage] = useState<any>("");

    const divRef = useRef(null);

    function get_user_profile() {
        axios.get(`${NETWORK_URL}/userApi`, {
            headers: {
                "x-access-token": user.auth_token
            }
        })
            .then((response) => {

                setsubscribed_till(response.data.subscription_valid_till);

                settotal_storage(response.data.size_json.available);

            })
            .catch(function (error: any) {
                console.log(error.response.data.message);
            });
    }

    useFocusEffect(
        React.useCallback(() => {

            axios.get(`${NETWORK_URL}/postApi/get`, {
                params: {
                    user_id: user.userDetails._id
                },
                headers: {
                    "x-access-token": user.auth_token
                }
            })
                .then((response) => {

                    setposts(response.data);

                })
                .catch(function (error: any) {

                    alert(error.response.data.message);

                });

            get_user_profile();

        }, [])
    );

    return (

        <Navigation navigation={navigation} children={
            <ScrollView style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItems: "center", margin: 10 }}>
                    <TouchableOpacity style={type_media == "image" ? { backgroundColor: "#20CCD6", paddingHorizontal: 10, paddingVertical: 5, borderRadius: 10 } : { paddingHorizontal: 10, paddingVertical: 5 }}
                        onPress={() => {

                            setselected_image([]);
                            setselected_image_url([]);
                            setshowimage({});
                            settype_media("image");

                        }}>
                        <Text style={{ color: "white" }}>Pictures</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={type_media == "video" ? { marginLeft: 10, backgroundColor: "#20CCD6", paddingHorizontal: 10, paddingVertical: 5, borderRadius: 10 } : { marginLeft: 10, paddingHorizontal: 10, paddingVertical: 5 }}
                        onPress={() => {

                            setselected_image([]);
                            setselected_image_url([]);
                            setshowimage({});
                            settype_media("video");

                        }}>
                        <Text style={{ color: "white" }}>Videos</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", marginLeft: wp(100) <= 426 ? 0 : 10, marginTop: 10 }}>
                    {posts.map((image, index) => {



                        if (type_media == "image")
                            return (
                                <>
                                    {
                                        image.image_url.map((image1, index) => {
                                            return (
                                                image1.url.indexOf("m3u8") > -1 ?
                                                    <></>
                                                    :
                                                    <Hoverable
                                                        style={{ width: wp(100) <= 426 ? wp(33) : hp(20), height: wp(100) <= 426 ? wp(33) : hp(20) }}
                                                        onHoverIn={() => {
                                                            var temp = JSON.parse(JSON.stringify(showimage));
                                                            temp[image1.url + "_|_" + index] = true;
                                                            Object.keys(temp).map((value, index12) => {
                                                                if (value != image1.url + "_|_" + index) temp[value] = false;
                                                            });
                                                            setshowimage(temp);
                                                        }}
                                                        onHoverOut={() => {
                                                            var temp = JSON.parse(JSON.stringify(showimage));
                                                            temp[image1.url + "_|_" + index] = false;
                                                            setshowimage(temp);
                                                        }}
                                                    >
                                                        {isHovered => (
                                                            <View>
                                                                <TouchableOpacity onPress={() => {

                                                                    if (selected_image_url.length > 0) {
                                                                        if (selected_image_url.includes(image1.url + "_|_" + index)) {
                                                                            var temp = selected_image_url.filter(function (item, key) {
                                                                                return item !== image1.url + "_|_" + index
                                                                            })
                                                                            setselected_image_url(temp);

                                                                            temp = selected_image.filter(function (item, key) {
                                                                                return item.url !== image1.url + "_|_" + index
                                                                            })
                                                                            setselected_image(temp);
                                                                        }
                                                                        else {
                                                                            setselected_image_url([...selected_image_url, image1.url + "_|_" + index])
                                                                            setselected_image([...selected_image, { url: image1.url + "_|_" + index, size: image1.size }])
                                                                        }
                                                                    }
                                                                    else {
                                                                        setimage_show({ url: image1.url, size: image1.size });
                                                                        setmodalshowimage(true);
                                                                    }

                                                                }}
                                                                    onLongPress={() => {

                                                                        if (selected_image_url.length <= 0) {
                                                                            setselected_image_url([...selected_image_url, image1.url + "_|_" + index])
                                                                            setselected_image([...selected_image, { url: image1.url + "_|_" + index, size: image1.size }])
                                                                        }
                                                                        else {
                                                                            var temp = selected_image_url.filter(function (item, key) {
                                                                                return item !== image1.url + "_|_" + index
                                                                            })
                                                                            setselected_image_url(temp);

                                                                            var temp = selected_image.filter(function (item, key) {
                                                                                return item.url !== image1.url + "_|_" + index
                                                                            })
                                                                            setselected_image(temp);
                                                                        }

                                                                    }}>
                                                                    <Image source={{ uri: image1.url }} style={{ width: wp(100) <= 426 ? wp(33) : hp(20), height: wp(100) <= 426 ? wp(33) : hp(20) }} resizeMode={"cover"} />
                                                                </TouchableOpacity>
                                                                {(showimage[image1.url + "_|_" + index] || selected_image_url.includes(image1.url + "_|_" + index)) && subscribed_till != "" && <View style={{ position: "absolute", top: 5, left: 5 }}>
                                                                    <input id={index} type="checkbox" checked={selected_image_url.includes(image1.url + "_|_" + index)} defaultChecked={false} onChange={(event) => {

                                                                        if (event.currentTarget.checked) {
                                                                            // alert(image1.size);
                                                                            setselected_image([...selected_image, { url: image1.url + "_|_" + index, size: image1.size }]);
                                                                            setselected_image_url([...selected_image_url, image1.url + "_|_" + index]);
                                                                        }
                                                                        else {
                                                                            var temp = selected_image.filter(function (item, key) {
                                                                                return item.url !== image1.url + "_|_" + index
                                                                            })
                                                                            setselected_image(temp);

                                                                            temp = selected_image_url.filter(function (item, key) {
                                                                                return item !== image1.url + "_|_" + index
                                                                            })
                                                                            setselected_image_url(temp);
                                                                        }

                                                                    }} style={{ backgroundColor: "black", height: 20, width: 20 }} />
                                                                    {/* <label for={index}><img style={{ height: 20, width: 20, cursor: "pointer" }} src={selected_image_url.includes(image1.url + "_|_" + index) ? "https://cdn3.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-1/254000/52-512.png" : "https://upload.wikimedia.org/wikipedia/commons/5/5e/Gray_tick.png"} /></label> */}
                                                                </View>}
                                                            </View>
                                                        )}
                                                    </Hoverable>

                                            )
                                        })
                                    }
                                </>
                            )
                        else
                            return (
                                <>
                                    {
                                        image.image_url.map((image1, index) => {
                                            return (
                                                image1.url.indexOf("m3u8") > -1 ?
                                                    <Hoverable
                                                        style={{ width: wp(100) <= 426 ? wp(33) : hp(20), height: wp(100) <= 426 ? wp(33) : hp(20) }}
                                                        onHoverIn={() => {
                                                            var temp = JSON.parse(JSON.stringify(showimage));
                                                            temp[image1.url] = true;
                                                            Object.keys(temp).map((value, index12) => {
                                                                if (value != image1.url) temp[value] = false;
                                                            });
                                                            setshowimage(temp);
                                                        }}
                                                        onHoverOut={() => {
                                                            var temp = JSON.parse(JSON.stringify(showimage));
                                                            temp[image1.url] = false;
                                                            setshowimage(temp);
                                                        }}
                                                    >
                                                        {isHovered => (
                                                            <View>
                                                                <TouchableOpacity style={{ width: wp(100) <= 426 ? wp(33) : hp(20), height: wp(100) <= 426 ? wp(33) : hp(20), justifyContent: "center" }}
                                                                    onPress={() => {

                                                                        if (selected_image_url.length > 0) {
                                                                            if (selected_image_url.includes(image1.url + "_|_" + index)) {
                                                                                var temp = selected_image.filter(function (item, key) {
                                                                                    return item.url !== image1.url.substring(image1.url.indexOf("m3u8") + 4)
                                                                                })
                                                                                setselected_image(temp);

                                                                                temp = selected_image_url.filter(function (item, key) {
                                                                                    return item !== image1.url.substring(image1.url.indexOf("m3u8") + 4)
                                                                                })
                                                                                setselected_image_url(temp);
                                                                            }
                                                                            else {
                                                                                setselected_image([...selected_image, { url: image1.url.substring(image1.url.indexOf("m3u8") + 4), size: image1.size }]);
                                                                                setselected_image_url([...selected_image_url, image1.url.substring(image1.url.indexOf("m3u8") + 4)]);
                                                                            }
                                                                        }
                                                                        else {
                                                                            setimage_show({ url: image1.url, size: image1.size });
                                                                            setmodalshowimage(true);
                                                                        }

                                                                    }}
                                                                    onLongPress={() => {

                                                                        if (selected_image_url.length <= 0) {
                                                                            setselected_image([...selected_image, { url: image1.url.substring(image1.url.indexOf("m3u8") + 4), size: image1.size }]);
                                                                            setselected_image_url([...selected_image_url, image1.url.substring(image1.url.indexOf("m3u8") + 4)]);
                                                                        }
                                                                        else {
                                                                            var temp = selected_image.filter(function (item, key) {
                                                                                return item.url !== image1.url.substring(image1.url.indexOf("m3u8") + 4)
                                                                            })
                                                                            setselected_image(temp);

                                                                            temp = selected_image_url.filter(function (item, key) {
                                                                                return item !== image1.url.substring(image1.url.indexOf("m3u8") + 4)
                                                                            })
                                                                            setselected_image_url(temp);
                                                                        }

                                                                    }}>
                                                                    <View style={{ width: "100%", height: "100%" }}>
                                                                        {/* <ReactHlsPlayer
                                                                            src={image1.url.substring(0, image1.url.indexOf("m3u8") + 4)}
                                                                            autoPlay={false}
                                                                            controls={false}
                                                                            hlsConfig={{
                                                                                startPosition: 1
                                                                            }}
                                                                            width={"100%"}
                                                                            height={"100%"}
                                                                            style={{ position: "relative" }}
                                                                        /> */}
                                                                        <Video
                                                                            style={{ width: "100%", height : "100%", position: "relative" }}
                                                                            source={{
                                                                                uri: image1.url.substring(image1.url.indexOf("m3u8") + 4),
                                                                            }}
                                                                            useNativeControls={false}
                                                                        />
                                                                    </View>
                                                                    <Image source={{ uri: "https://icon-library.com/images/video-play-icon/video-play-icon-0.jpg" }} style={{ height: 20, width: 20, position: "absolute", alignSelf: "center" }} />
                                                                </TouchableOpacity>
                                                                {(showimage[image1.url] || selected_image_url.includes(image1.url.substring(image1.url.indexOf("m3u8") + 4))) && subscribed_till != "" && <View style={{ position: "absolute", top: 5, left: 5 }}>
                                                                    <input id={index} type="checkbox" checked={selected_image_url.includes(image1.url.substring(image1.url.indexOf("m3u8") + 4))} defaultChecked={false} onChange={(event) => {

                                                                        if (event.currentTarget.checked) {
                                                                            setselected_image([...selected_image, { url: image1.url.substring(image1.url.indexOf("m3u8") + 4), size: image1.size }]);
                                                                            setselected_image_url([...selected_image_url, image1.url.substring(image1.url.indexOf("m3u8") + 4)]);
                                                                        }
                                                                        else {
                                                                            var temp = selected_image.filter(function (item, key) {
                                                                                return item.url !== image1.url.substring(image1.url.indexOf("m3u8") + 4)
                                                                            })
                                                                            setselected_image(temp);

                                                                            temp = selected_image_url.filter(function (item, key) {
                                                                                return item !== image1.url.substring(image1.url.indexOf("m3u8") + 4)
                                                                            })
                                                                            setselected_image_url(temp);
                                                                        }

                                                                    }} style={{ backgroundColor: "black", height: 20, width: 20 }} />
                                                                    {/* <label for={index}><img style={{ height: 20, width: 20, cursor: "pointer" }} src={selected_image_url.includes(image1.url.substring(image1.url.indexOf("m3u8") + 4)) ? "https://cdn3.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-1/254000/52-512.png" : "https://upload.wikimedia.org/wikipedia/commons/5/5e/Gray_tick.png"} /></label> */}
                                                                </View>}
                                                            </View>
                                                        )}
                                                    </Hoverable>
                                                    :
                                                    <></>
                                            )
                                        })
                                    }
                                </>
                            )

                    })};
                </View>
                {selected_image.length > 0 &&
                    <TouchableOpacity style={{ position: "absolute", right: 10, top: 10, padding: 10, backgroundColor: "white", borderRadius: 50 }}
                        onPress={() => {

                            // alert(JSON.stringify(selected_image));

                            var temp = [];

                            selected_image.map(async (image1, index) => {

                                if (image1.url.indexOf(".mp4") > -1) {
                                    const res = await axios.post(`${NETWORK_URL}/postApi/download_video`, {
                                        image_url: image1.url,
                                        size: image1.size,
                                        type: "profile"
                                    }, {
                                        headers: {
                                            "x-access-token": user.auth_token
                                        }
                                    });
                                    temp.push(image1.url);
                                }
                                else {
                                    const res = await axios.post(`${NETWORK_URL}/postApi/download`, {
                                        image_url: image1.url.substring(0, image1.url.indexOf("_|_")),
                                        size: image1.size,
                                        type: "profile"
                                    }, {
                                        headers: {
                                            "x-access-token": user.auth_token
                                        }
                                    });
                                    //  alert(res.data.download_url); // (Or whatever)

                                    temp.push(res.data.download_url);
                                }

                                // alert(index);

                                if (temp.length >= selected_image.length) {
                                    // alert(JSON.stringify(temp));
                                    download_files(temp);
                                }

                            });


                            setTimeout(() => {
                                setselected_image([])
                                setselected_image_url([])
                            }, 3000)

                        }}>
                        <Image source={{ uri: "https://cdn.iconscout.com/icon/free/png-256/download-button-1723003-1465295.png" }} style={{ height: 50, width: 50 }} />
                    </TouchableOpacity>}

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalshowimage}
                    onRequestClose={() => {
                        setmodalshowimage(!modalshowimage);
                    }}>
                    <View style={styles.centeredView_white}>
                        <View style={styles.modalView_white}>

                            <View style={{ alignItems: 'center' }}>
                                <View style={styles.header}>
                                    <TouchableOpacity onPress={() => setmodalshowimage(false)}>
                                        <Text style={{ color: "black", fontSize: 18 }}>x</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>

                            <View style={{ height: wp(100) <= 820 ? hp(40) : hp(90), width: "100%" }}>
                                {image_show?.url?.indexOf("m3u8") <= -1 ?
                                    <View style={{ width: "100%", height: "100%" }}>
                                        <Image
                                            source={{ uri: image_show.url }}
                                            style={{ width: "100%", height: "90%" }}
                                            resizeMode={"contain"}
                                        />
                                        <TouchableOpacity onPress={() => {
                                            // alert(elem1.substring( elem1.indexOf("m3u8") + 1));

                                            // alert(elem1);

                                            axios.post(`${NETWORK_URL}/postApi/download`, {

                                                image_url: image_show.url,
                                                size: image_show.size,
                                                type: "profile"

                                            }, {
                                                headers: {
                                                    "x-access-token": user.auth_token
                                                }
                                            })
                                                .then((response) => {

                                                    downloadURI(response.data.download_url, "sample.jpeg");

                                                })
                                                .catch(function (error: any) {

                                                    alert(JSON.stringify(error.response.data));

                                                });

                                            setTimeout(() => {
                                                setselected_image([])
                                                setselected_image_url([])
                                            }, 3000)

                                            // createFile(elem1);
                                        }} style={{ position: "absolute", right: 10, bottom: 40 }}>
                                            <Image source={{ uri: "https://cdn.iconscout.com/icon/free/png-256/download-button-1723003-1465295.png" }} style={{ height: 50, width: 50 }} />
                                        </TouchableOpacity>
                                    </View> :
                                    <View style={{ width: "100%", height: "100%" }}>
                                        {/* <ReactHlsPlayer
                                            src={image_show?.url?.substring(0, image_show?.url?.indexOf("m3u8") + 4)}
                                            autoPlay={true}
                                            controls={false}
                                            loop={true}
                                            width={"100%"}
                                            height={"90%"}
                                            style={{ position: "relative" }}
                                        /> */}
                                        <Video
                                            style={{ width: "100%", height: "90%", position: "relative" }}
                                            source={{
                                                uri: image_show?.url?.substring(image_show?.url?.indexOf("m3u8") + 4),
                                            }}
                                            useNativeControls={true}
                                            resizeMode={"contain"}
                                        />
                                        <TouchableOpacity onPress={() => {
                                            // alert(elem1.substring( elem1.indexOf("m3u8") + 1));

                                            axios.post(`${NETWORK_URL}/postApi/download_video`, {

                                                image_url: image_show.url,
                                                size: image_show.size,
                                                type: "profile"

                                            }, {
                                                headers: {
                                                    "x-access-token": user.auth_token
                                                }
                                            })
                                                .then((response) => {

                                                    downloadURI(image_show?.url?.substring(image_show?.url?.indexOf("m3u8") + 4), "sample.jpeg");

                                                })
                                                .catch(function (error: any) {

                                                    alert(JSON.stringify(error.response.data));

                                                });

                                            // createFile(elem1);

                                            setTimeout(() => {
                                                setselected_image([])
                                                setselected_image_url([])
                                            }, 3000)

                                        }} style={{ position: "absolute", right: 10, bottom: 40 }}>
                                            <Image source={{ uri: "https://cdn.iconscout.com/icon/free/png-256/download-button-1723003-1465295.png" }} style={{ height: 50, width: 50 }} />
                                        </TouchableOpacity>
                                    </View>}
                            </View>

                        </View>

                    </View>
                </Modal>
            </ScrollView>

        } />

    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        overflowX: "hidden"
    },
    orHolder: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 15
    },
    orText: {
        color: colors.secondary,
        backgroundColor: '#fff',
        fontSize: scale(14)
    },
    SocialMediaButtonHolder: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: hp(3)
    },
    socislMediaButton: {
        height: wp(100) <= 768 ? wp(6) : wp(1.5),
        width: wp(100) <= 768 ? wp(6) : wp(1.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : wp(2)
    },
    storeMediaButton: {
        height: wp(100) <= 768 ? wp(30) : wp(3.5),
        width: wp(100) <= 768 ? wp(30) : wp(8.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : scale(7)
    },
    centeredView_white: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    modalView_white: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: wp(100) <= 820 ? wp(80) : wp(45),
        maxHeight: hp(95)
    },
    header: {
        width: '100%',
        height: wp(100) <= 426 ? 10 : 40,
        alignItems: 'flex-end',
        justifyContent: 'center',
    }
});