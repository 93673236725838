import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import {
  TextInput,
  ActivityIndicator,
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  ImageBackground,
  TouchableOpacity,
  Button,
  Modal,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { scale, colors, device, wp, hp } from "../utils";
import * as WebBrowser from "expo-web-browser";
import { ResponseType } from "expo-auth-session";
import * as Google from "expo-auth-session/providers/google";
import { initializeApp } from "firebase/app";
import * as Facebook from "expo-auth-session/providers/facebook";
import {
  header_logo,
  search_icon,
  cloud_icon,
  share_icon,
  filter_icon,
  blank_heart,
  red_heart,
  funding_money,
  btn_donate,
  expense_icon,
} from "../assets";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reduxStore/store";
import { NETWORK_URL } from "../utils/config";
import { storeToken, userDetails } from "../reduxStore/actions";
import { CommonActions, useFocusEffect } from "@react-navigation/native";
import LinearGradient from "react-native-web-linear-gradient";
import Navigation from "./Navigation";
import PhotoGrid from "react-native-thumbnail-grid";
import { Swiper } from "../Swiper_web/Swiper_web";
import ReactHlsPlayer from "react-hls-player";
import Hoverable from "./Hoverable";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { AntDesign } from "@expo/vector-icons";
import { Popover } from "react-native-popable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Video } from "expo-av";

import { Card, ProgressBar } from "react-native-paper";
import UpgradeSelect from "./UpgradeSelect";
import base64 from "react-native-base64";

const stripePromise = loadStripe(
  "pk_live_51L1LlBIPu7IYyJxOMn5jwbhjM2XNVKHr5TUjn6SnbONgb0CP8m2kHYs81L2LM6323MYI3MFsndI76DLPVecsGGhq00atSH9lgb"
);

WebBrowser.maybeCompleteAuthSession();

initializeApp({
  apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
  authDomain: "farmhomes-bcefb.firebaseapp.com",
  projectId: "farmhomes-bcefb",
  storageBucket: "farmhomes-bcefb.appspot.com",
  messagingSenderId: "718737440796",
  appId: "1:718737440796:web:395567042f5eee01329fd9",
  measurementId: "G-NGCNHJQGG5",
});

function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // delete link;
}

function download_files(files) {
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    var a = document.createElement("a");
    a.href = files[i];
    a.target = "_parent";
    // Use a.download if available, it prevents plugins from opening.
    // if ('download' in a) {
    //     a.download = "sample.jpeg";
    // }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    } else {
      // $(a).click(); // Backup using jquery
    }
    // Delete the temporary link.
    //   a.parentNode.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      download_next(i + 1);
    }, 2000);
  }
  // Initiate the first download.
  download_next(0);
}

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

async function createFile() {
  let response = await fetch("http://127.0.0.1:8080/test.jpg");
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let file = new File([data], "test.jpg", metadata);
  console.log(file);
  // ... do something with the file or return it
}

var event_id_global = "";

export default function EventHome({ navigation, route }: any) {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<String | null>(null);
  const [modalshowimage, setmodalshowimage] = useState<any>(false);
  const user = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch();
  const [posts, setposts] = useState<any>([]);
  const [event_photo, setevent_photo] = useState<any>({});
  const [event_info, setevent_info] = useState<any>({});

  const [selected_image, setselected_image] = useState<any>([]);

  const [selected_image_url, setselected_image_url] = useState<any>([]);

  const [showimage, setshowimage] = useState<any>({});

  const [options, setoptions] = useState<any>({});

  const [modalpaymentgateway, setmodalpaymentgateway] = useState<any>(false);

  const [modalpaymentpreview, setmodalpaymentpreview] = useState<any>(false);

  const [amount_donate, setamount_donate] = useState<any>("");

  const [card_show, setcard_show] = useState<any>(false);

  const [modalfundlisting, setmodalfundlisting] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);

  const [fundlisting, setfundlisting] = useState<any>([]);

  const [filter_visible, setfilter_visible] = useState<any>(false);
  const [expenseVisible, setExpenseVisible] = useState<any>(false);

  const [expensesplit, setexpensesplit] = useState<any>(false);

  const [modalExpenseListing, setModalExpenseListing] = useState<any>([]);

  const [expense_total, setexpense_total] = useState<any>(0);

  const [subscribed_till, setsubscribed_till] = useState<any>("");

  const [total_storage, settotal_storage] = useState<any>("");

  const [raw_available, setraw_available] = useState<any>(0);

  const [upgradealert, showupgradealert] = useState(false);
  const [planselect, setplanselect] = useState(false);

  const notify = (text) => toast(text);

  const [userProfile, setUserProfile] = useState({});

  // var options = {
  //     // passing the client secret obtained from the server
  //     clientSecret: 'sk_test_51KvEYZSEMDT3aMr3mR7jD5KXe52K1xPdakGFIWMlOigaIJ9B24gyNgzS2WNmxJjhPNMQ3okfenp1b5GWGjbgjtuu00E3S5tBBb',
  // };

  function get_user_profile() {
    axios
      .get(`${NETWORK_URL}/userApi`, {
        headers: {
          "x-access-token": user.auth_token,
        },
      })
      .then((response) => {
        setUserProfile(response.data);

        setsubscribed_till(response.data.subscription_valid_till);

        // alert(JSON.stringify(response.data.size_json));

        settotal_storage(response.data.size_json.available);

        setraw_available(response.data.size_json.raw_available);
      })
      .catch(function (error: any) {
        console.log(error.response.data.message);
      });
  }

  const CheckoutForm = () => {
    const stripe = useStripe();

    const elements = useElements();

    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      var url = window.location.href;
      var arr = url.split("/");
      var result12 = arr[0] + "//" + arr[2];

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url:
            result12 +
            "/event?event_id=" +
            window.atob(route.params.id) +
            "&funding=true",
        },
      });

      // alert("error");
      // alert(result.error);

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        alert(result.error.message);
      } else {
      }
    };

    return (
      <form
        style={{ alignItems: "center", justifyContent: "center" }}
        onSubmit={handleSubmit}
      >
        <PaymentElement />
        <button
          style={{
            height: 30,
            width: 80,
            borderRadius: 20,
            color: "white",
            backgroundColor: "#FF5C5B",
            marginTop: 20,
            alignSelf: "center",
            cursor: "pointer",
          }}
        >
          Pay
        </button>
      </form>
    );
  };

  function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  function get_sort_post(sort_type) {
    // setposts([])

    // posts.sort((a: any, b: any) => a.total_likes > b.total_likes)
    // // posts.reverse()
    // setposts(sortedArray)
    // setfilter_visible(false)

    setposts([]);
    setfilter_visible(false);
    axios
      .get(`${NETWORK_URL}/postApi/get`, {
        params: {
          sort_type: sort_type,
          event_id: window.atob(route.params.id),
        },
        headers: {
          "x-access-token": user.auth_token,
        },
      })
      .then((response) => {
        console.log("response.data", response.data);
        if (sort_type === "like")
          setposts(
            response.data.sort(
              (a: any, b: any) => b.total_likes - a.total_likes
            )
          );
        else if (sort_type === "user")
          setposts(
            response.data.sort((a: any, b: any): any =>
              a.user.fullName.localeCompare(b.user.fullName)
            )
          );
        else if (sort_type === "date")
          setposts(
            response.data.sort(
              (a: any, b: any): any => a.createdAt - b.createdAt
            )
          );
      })
      .catch(function (error: any) {
        alert(error.response.data.message);
      });
  }
  // users.sort((a, b) => a.firstname.localeCompare(b.firstname))
  useEffect(() => {
    if (expenseVisible) {
      axios
        .get(
          `${NETWORK_URL}/expenseApi?event_id=` + window.atob(route.params.id),
          {
            headers: {
              "x-access-token": user.auth_token,
            },
          }
        )
        .then((response) => {
          // alert(JSON.stringify(response.data));
          setModalExpenseListing(response.data.data.list);
          setexpense_total(response.data.data.total);
        })
        .catch(function (error: any) {
          alert(error.response.data.message);
        });
    }
  }, [expenseVisible]);

  useEffect(() => {
    if (expensesplit) {
      axios
        .get(
          `${NETWORK_URL}/expenseApi/split?event_id=` +
          window.atob(route.params.id),
          {
            headers: {
              "x-access-token": user.auth_token,
            },
          }
        )
        .then((response) => {
          // alert(JSON.stringify(response.data));
          // setModalExpenseListing(response.data.data.list)

          var temp = [];

          response.data.map((txn, index) => {
            temp.push({
              user: {
                profile_picture: txn.profile_picture,
                fullName: txn.fullName,
              },
              amount: txn.balance,
            });
          });

          setModalExpenseListing(temp);
        })
        .catch(function (error: any) {
          alert(error.response.data.message);
        });
    } else {
      axios
        .get(
          `${NETWORK_URL}/expenseApi?event_id=` + window.atob(route.params.id),
          {
            headers: {
              "x-access-token": user.auth_token,
            },
          }
        )
        .then((response) => {
          // alert(JSON.stringify(response.data));
          setModalExpenseListing(response.data.data.list);
          setexpense_total(response.data.data.total);
        })
        .catch(function (error: any) {
          alert(error.response.data.message);
        });
    }
  }, [expensesplit]);

  useFocusEffect(
    React.useCallback(() => {
      event_id_global = window.atob(route.params.id);

      // alert(route?.params?.payment_intent_client_secret);

      if (
        route?.params?.payment_intent_client_secret &&
        route?.params?.funding
      ) {
        axios
          .post(
            `${NETWORK_URL}/fundApi/update`,
            {
              payment_id: route?.params?.payment_intent_client_secret,
            },
            {
              headers: {
                "x-access-token": user.auth_token,
              },
            }
          )
          .then((response) => {
            alert("Donation done");

            // notify("Donation done");

            var url = window.location.href;
            var arr = url.split("/");
            var result12 = arr[0] + "//" + arr[2];

            window.location.href =
              result12 + "/event?event_id=" + window.atob(route.params.id);
          })
          .catch(function (error: any) { });
      }

      axios
        .get(`${NETWORK_URL}/postApi/get`, {
          params: {
            event_id: window.atob(route.params.id),
          },
          headers: {
            "x-access-token": user.auth_token,
          },
        })
        .then((response) => {
          // alert(JSON.stringify(response.data));

          setposts(
            response.data.sort(
              (a: any, b: any) => b.total_likes - a.total_likes
            )
          );
        })
        .catch(function (error: any) {
          alert(error.response.data.message);
        });

      axios
        .get(`${NETWORK_URL}/eventApi/get`, {
          params: {
            id: window.atob(route.params.id),
            user_id: user.userDetails._id,
          },
          headers: {
            "x-access-token": user.auth_token,
          },
        })
        .then((response) => {
          if (response.data.length <= 0) {
            alert(
              "You are not a member of this group. Please send a joint request for this event from Snapyfi app to continue"
            );
            window.location.href = "/home";
          } else setevent_info(response.data[0]);
        })
        .catch(function (error: any) {
          alert(error.response.data.message);
        });

      get_user_profile();
    }, [])
  );

  // React.useEffect(() => {
  //   const url = window.location.href;
  //   let a = url.split("?");
  //   let encoded = base64.encode(a[1]);
  //   alert(encoded);
  //   let encodedUrl = a[0] + "?" + encoded;
  //   window.location.href = encodedUrl;
  //   // alert(window.btoa(url));
  // }, []);

  function isNumber(evt: any) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      // return false;
    } else setamount_donate(evt);
  }

  return (
    <Navigation
      navigation={navigation}
      children={
        <ScrollView style={{ flex: 1 }}>
          <View
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {event_info?.settings?.expense != "0" ? (
              <TouchableOpacity
                style={{ marginRight: 10 }}
                onPress={() => {
                  setExpenseVisible(!expenseVisible);
                }}
              >
                <Image
                  source={expense_icon}
                  style={{ height: 20, width: 20 }}
                  resizeMode={"contain"}
                />
              </TouchableOpacity>
            ) : (
              <View style={{ marginRight: 10, height: 20, width: 20 }}>
                {/* <Image source={expense_icon} style={{ height: 20, width: 20 }} resizeMode={"contain"} /> */}
              </View>
            )}

            <TouchableOpacity
              onPress={() => {
                setfilter_visible(!filter_visible);
              }}
              style={{}}
            >
              {filter_visible ? (
                <Image
                  source={{
                    uri: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLzwrFoPEIpqjj4uXAsFuCbKVn5ChTtwLvbQrKbb6_ZvOPFjB-dzi4FrZRHjZEWRwFU_c&usqp=CAU",
                  }}
                  style={{ height: 24, width: 24 }}
                  resizeMode={"cover"}
                />
              ) : (
                <Image
                  source={filter_icon}
                  style={{ height: 18, width: 18 }}
                  resizeMode={"cover"}
                />
              )}
            </TouchableOpacity>
            <Popover
              position={wp(100) <= 425 ? "left" : "bottom"}
              visible={filter_visible}
              wrapperStyle={{ zIndex: 2 }}
              style={{
                zIndex: 2,
                width: wp(100) >= 425 ? wp(10) : wp(25),
                position: "absolute",
                top: wp(100) <= 425 ? 24 : hp(2),
                right: wp(100) <= 425 ? wp(4) : wp(2),
                alignSelf: "flex-start",
              }}
            >
              <TouchableOpacity
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  flexDirection: "row",
                  borderWidth: 1,
                }}
                onPress={() => {
                  get_sort_post("like");
                }}
              >
                <Text>Like</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  flexDirection: "row",
                  borderWidth: 1,
                }}
                onPress={() => {
                  get_sort_post("user");
                }}
              >
                <Text>User</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  flexDirection: "row",
                  borderWidth: 1,
                }}
                onPress={() => {
                  get_sort_post("date");
                }}
              >
                <Text>Date</Text>
              </TouchableOpacity>
            </Popover>

            {event_info.status != "Closed" && (
              <TouchableOpacity
                style={{ marginLeft: 10 }}
                onPress={() => {
                  copyToClipboard(window.location.href);
                  notify("Event Url copied to clipboard");
                }}
              >
                <Image
                  source={share_icon}
                  style={{ height: 20, width: 20 }}
                  resizeMode={"contain"}
                />
              </TouchableOpacity>
            )}
          </View>

          {wp(100) > 426 &&
            event_info.status != null &&
            event_info.status != "Closed" &&
            event_info?.settings?.funding != "0" && (
              <TouchableOpacity
                style={{
                  position: "absolute",
                  top: 100,
                  left: wp(100) <= 426 ? wp(5) : wp(20),
                  alignItems: "center",
                  flexDirection: wp(100) <= 426 ? "column" : "row",
                }}
                onPress={() => {
                  setmodalfundlisting(true);

                  axios
                    .get(
                      `${NETWORK_URL}/fundApi?event_id=` +
                      window.atob(route.params.id),
                      {
                        headers: {
                          "x-access-token": user.auth_token,
                        },
                      }
                    )
                    .then((response) => {
                      setfundlisting(response.data);
                      setLoading(false);
                    })
                    .catch(function (error: any) {
                      alert(error.response.data.message);
                    });
                }}
              >
                <Image
                  source={funding_money}
                  style={{
                    width: wp(100) <= 426 ? wp(10) : wp(3),
                    height: wp(100) <= 426 ? wp(10) : wp(3),
                  }}
                  resizeMode={"contain"}
                />
                <Text
                  style={{
                    color: "white",
                    fontSize: 18,
                    marginTop: wp(100) <= 426 ? 5 : 0,
                    marginLeft: wp(100) <= 426 ? 0 : 15,
                  }}
                >
                  {event_info["total_funding"]}$
                </Text>
              </TouchableOpacity>
            )}

          {wp(100) > 426 &&
            event_info.status != null &&
            event_info.status != "Closed" &&
            event_info?.settings?.funding != "0" && (
              <TouchableOpacity
                style={{
                  zIndex: 1,
                  position: "absolute",
                  top: 90,
                  right: wp(100) <= 426 ? wp(5) : wp(20),
                  alignItems: "center",
                  backgroundColor: "#FF5C5B",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  flexDirection: "row",
                  borderRadius: 20,
                }}
                onPress={() => {
                  // alert("Please enable third party cookies for payment to work!");
                  setmodalpaymentpreview(true);
                }}
              >
                <Image
                  source={btn_donate}
                  style={{
                    width: wp(100) <= 426 ? wp(10) : wp(2),
                    height: wp(100) <= 426 ? wp(10) : wp(2),
                  }}
                  resizeMode={"contain"}
                />
                <Text style={{ color: "white", fontSize: 16, marginLeft: 10 }}>
                  Gift
                </Text>
              </TouchableOpacity>
            )}

          <View
            style={{ alignSelf: "center", alignItems: "center", margin: 20 }}
          >
            <Image
              source={{
                uri: !event_info?.event_image ?
                  "https://cdn.dscovr.com/images/project-default.webp" :
                  event_info?.event_image
              }}
              style={{
                zIndex: 1,
                width: wp(100) >= 426 ? 200 : 100,
                height: wp(100) >= 426 ? 200 : 100,
                borderRadius: wp(100) >= 426 ? 200 : 100,
              }}
              resizeMode={"cover"}
            />

            {wp(100) <= 426 &&
              event_info.status != null &&
              event_info.status != "Closed" &&
              event_info?.settings?.funding != "0" &&
              !filter_visible && (
                <View
                  style={{
                    zIndex: 1,
                    flexDirection: "row",
                    width: wp(90),
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      flexDirection: wp(100) <= 426 ? "column" : "row",
                    }}
                    onPress={() => {
                      setmodalfundlisting(true);
                      axios
                        .get(
                          `${NETWORK_URL}/fundApi?event_id=` +
                          window.atob(route.params.id),
                          {
                            headers: {
                              "x-access-token": user.auth_token,
                            },
                          }
                        )
                        .then((response) => {
                          setfundlisting(response.data);
                          setLoading(false);
                        })
                        .catch(function (error: any) {
                          alert(error.response.data.message);
                        });
                    }}
                  >
                    <Image
                      source={funding_money}
                      style={{
                        width: wp(100) <= 426 ? wp(10) : wp(3),
                        height: wp(100) <= 426 ? wp(10) : wp(3),
                      }}
                      resizeMode={"contain"}
                    />
                    <Text
                      style={{
                        color: "white",
                        fontSize: 12,
                        marginTop: wp(100) <= 426 ? 5 : 0,
                        marginLeft: wp(100) <= 426 ? 0 : 15,
                      }}
                    >
                      {event_info["total_funding"]}$
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      zIndex: 1,
                      alignItems: "center",
                      backgroundColor: "#FF5C5B",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      flexDirection: "row",
                      borderRadius: 20,
                    }}
                    onPress={() => {
                      setmodalpaymentpreview(true);
                    }}
                  >
                    <Image
                      source={btn_donate}
                      style={{
                        width: wp(100) <= 426 ? wp(6) : wp(2),
                        height: wp(100) <= 426 ? wp(6) : wp(2),
                      }}
                      resizeMode={"contain"}
                    />
                    <Text
                      style={{ color: "white", fontSize: 12, marginLeft: 10 }}
                    >
                      Gift....
                    </Text>
                  </TouchableOpacity>
                </View>
              )}

            <Text style={{ color: "white", fontSize: 25, marginTop: 20 }}>
              {event_info?.name}
            </Text>
            <Text style={{ color: "white", fontSize: 15, marginTop: 20 }}>
              {event_info?.description}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: wp(100) <= 426 ? 0 : 10,
              marginTop: 10,
            }}
          >
            {posts.map((image, index) => {
              return (
                <Hoverable
                  style={{
                    width: wp(100) <= 426 ? wp(33) : hp(20),
                    height: wp(100) <= 426 ? wp(33) : hp(20),
                  }}
                  onHoverIn={() => {
                    var temp = JSON.parse(JSON.stringify(showimage));
                    temp[index] = true;
                    Object.keys(temp).map((value, index12) => {
                      if (value != index) temp[value] = false;
                    });
                    setshowimage(temp);
                  }}
                  onHoverOut={() => {
                    var temp = JSON.parse(JSON.stringify(showimage));
                    temp[index] = false;
                    setshowimage(temp);
                  }}
                >
                  {(isHovered) => (
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          if (selected_image_url.length > 0) {
                            var temp11 = selected_image;
                            var temp12 = selected_image;

                            var temp132 = selected_image_url;
                            var temp142 = selected_image_url;

                            image.image_url.map((image12, index12) => {
                              // alert(event.currentTarget.checked);

                              if (
                                !selected_image_url.includes(
                                  image.image_url[0].url.substring(
                                    image.image_url[0].url.indexOf("m3u8") + 4
                                  ) +
                                  "_|_" +
                                  index +
                                  "0"
                                ) &&
                                !selected_image_url.includes(
                                  image.image_url[0].url + "_|_" + index + "0"
                                )
                              ) {
                                if (image12.url.indexOf("m3u8") > -1)
                                  temp11 = [
                                    ...temp11,
                                    {
                                      url:
                                        image12.url.substring(
                                          image12.url.indexOf("m3u8") + 4
                                        ) +
                                        "_|_" +
                                        index +
                                        index12,
                                      size: image12.size,
                                      postedBy: image.postedBy,
                                    },
                                  ];
                                else
                                  temp11 = [
                                    ...temp11,
                                    {
                                      url:
                                        image12.url + "_|_" + index + index12,
                                      size: image12.size,
                                      postedBy: image.postedBy,
                                    },
                                  ];

                                if (image12.url.indexOf("m3u8") > -1)
                                  temp132 = [
                                    ...temp132,
                                    image12.url.substring(
                                      image12.url.indexOf("m3u8") + 4
                                    ) +
                                    "_|_" +
                                    index +
                                    index12,
                                  ];
                                else
                                  temp132 = [
                                    ...temp132,
                                    image12.url + "_|_" + index + index12,
                                  ];
                              } else {
                                if (image12.url.indexOf("m3u8") > -1)
                                  temp12 = temp12.filter(function (item, key) {
                                    return (
                                      item.url !==
                                      image12.url.substring(
                                        image12.url.indexOf("m3u8") + 4
                                      ) +
                                      "_|_" +
                                      index +
                                      index12
                                    );
                                  });
                                else
                                  temp12 = temp12.filter(function (item, key) {
                                    return (
                                      item.url !==
                                      image12.url + "_|_" + index + index12
                                    );
                                  });

                                if (image12.url.indexOf("m3u8") > -1)
                                  temp142 = temp142.filter(function (
                                    item,
                                    key
                                  ) {
                                    return (
                                      item !==
                                      image12.url.substring(
                                        image12.url.indexOf("m3u8") + 4
                                      ) +
                                      "_|_" +
                                      index +
                                      index12
                                    );
                                  });
                                else
                                  temp142 = temp142.filter(function (
                                    item,
                                    key
                                  ) {
                                    return (
                                      item !==
                                      image12.url + "_|_" + index + index12
                                    );
                                  });
                              }
                            });

                            if (
                              !selected_image_url.includes(
                                image.image_url[0].url.substring(
                                  image.image_url[0].url.indexOf("m3u8") + 4
                                ) +
                                "_|_" +
                                index +
                                "0"
                              ) &&
                              !selected_image_url.includes(
                                image.image_url[0].url + "_|_" + index + "0"
                              )
                            )
                              setselected_image(temp11);
                            else setselected_image(temp12);

                            if (
                              !selected_image_url.includes(
                                image.image_url[0].url.substring(
                                  image.image_url[0].url.indexOf("m3u8") + 4
                                ) +
                                "_|_" +
                                index +
                                "0"
                              ) &&
                              !selected_image_url.includes(
                                image.image_url[0].url + "_|_" + index + "0"
                              )
                            )
                              setselected_image_url(temp132);
                            else setselected_image_url(temp142);
                          } else {
                            setevent_photo({
                              urls: image.image_url,
                              postedBy: image.postedBy,
                              caption: image.caption,
                            });
                            setmodalshowimage(true);
                            console.log(image);
                          }
                        }}
                        onLongPress={() => {
                          var temp11 = selected_image;
                          var temp12 = selected_image;

                          var temp132 = selected_image_url;
                          var temp142 = selected_image_url;

                          image.image_url.map((image12, index12) => {
                            // alert(event.currentTarget.checked);

                            if (selected_image_url.length <= 0) {
                              if (image12.url.indexOf("m3u8") > -1)
                                temp11 = [
                                  ...temp11,
                                  {
                                    url:
                                      image12.url.substring(
                                        image12.url.indexOf("m3u8") + 4
                                      ) +
                                      "_|_" +
                                      index +
                                      index12,
                                    size: image12.size,
                                    postedBy: image.postedBy,
                                  },
                                ];
                              else
                                temp11 = [
                                  ...temp11,
                                  {
                                    url: image12.url + "_|_" + index + index12,
                                    size: image12.size,
                                    postedBy: image.postedBy,
                                  },
                                ];

                              if (image12.url.indexOf("m3u8") > -1)
                                temp132 = [
                                  ...temp132,
                                  image12.url.substring(
                                    image12.url.indexOf("m3u8") + 4
                                  ) +
                                  "_|_" +
                                  index +
                                  index12,
                                ];
                              else
                                temp132 = [
                                  ...temp132,
                                  image12.url + "_|_" + index + index12,
                                ];
                            } else {
                              if (image12.url.indexOf("m3u8") > -1)
                                temp12 = temp12.filter(function (item, key) {
                                  return (
                                    item.url !==
                                    image12.url.substring(
                                      image12.url.indexOf("m3u8") + 4
                                    ) +
                                    "_|_" +
                                    index +
                                    index12
                                  );
                                });
                              else
                                temp12 = temp12.filter(function (item, key) {
                                  return (
                                    item.url !==
                                    image12.url + "_|_" + index + index12
                                  );
                                });

                              if (image12.url.indexOf("m3u8") > -1)
                                temp142 = temp142.filter(function (item, key) {
                                  return (
                                    item !==
                                    image12.url.substring(
                                      image12.url.indexOf("m3u8") + 4
                                    ) +
                                    "_|_" +
                                    index +
                                    index12
                                  );
                                });
                              else
                                temp142 = temp142.filter(function (item, key) {
                                  return (
                                    item !==
                                    image12.url + "_|_" + index + index12
                                  );
                                });
                            }
                          });

                          if (selected_image_url.length <= 0)
                            setselected_image(temp11);
                          else setselected_image(temp12);

                          if (selected_image_url.length <= 0)
                            setselected_image_url(temp132);
                          else setselected_image_url(temp142);
                        }}
                      >
                        {image?.image_url[0]?.url.indexOf("m3u8") > -1 ? (
                          <View
                            style={{
                              width: wp(100) <= 426 ? wp(33) : hp(20),
                              height: wp(100) <= 426 ? wp(33) : hp(20),
                              justifyContent: "center",
                            }}
                          >
                            <View style={{ width: "100%", height: "100%" }}>
                              {/* <ReactHlsPlayer
                                                            src={image?.image_url[0]?.url.substring(0, image.image_url[0].url.indexOf("m3u8") + 4)}
                                                            autoPlay={false}
                                                            controls={false}
                                                            hlsConfig={{
                                                                startPosition: 1
                                                            }}
                                                            width={"100%"}
                                                            height={"100%"}
                                                            style={{ position: "relative" }}
                                                        /> */}
                              <Video
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "relative",
                                }}
                                source={{
                                  uri: image?.image_url[0]?.url.substring(
                                    image.image_url[0].url.indexOf("m3u8") + 4
                                  ),
                                }}
                                useNativeControls={false}
                              />
                            </View>
                            <Image
                              source={{
                                uri: "https://icon-library.com/images/video-play-icon/video-play-icon-0.jpg",
                              }}
                              style={{
                                height: 20,
                                width: 20,
                                position: "absolute",
                                alignSelf: "center",
                              }}
                            />
                          </View>
                        ) : (
                          <Image
                            source={{ uri: image?.image_url[0]?.url }}
                            style={{
                              width: wp(100) <= 426 ? wp(33) : hp(20),
                              height: wp(100) <= 426 ? wp(33) : hp(20),
                            }}
                            resizeMode={"cover"}
                          />
                        )}
                      </TouchableOpacity>
                      {image.image_url.length > 1 && (
                        <View
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            padding: 5,
                            borderRadius: 25,
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://cdn-icons-png.flaticon.com/512/74/74108.png",
                            }}
                            style={{ height: 20, width: 20 }}
                            resizeMode={"cover"}
                          />
                        </View>
                      )}
                      <View
                        style={{ position: "absolute", bottom: 5, left: 5 }}
                      >
                        <Image
                          source={{
                            uri: image.user.profile_picture
                              ? image.user.profile_picture
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
                          }}
                          style={{ width: 20, height: 20, borderRadius: 20 }}
                          resizeMode={"cover"}
                        />
                        <Text
                          style={{ color: "white", fontSize: 9, marginTop: 3 }}
                        >
                          {image.user.fullName}
                        </Text>
                      </View>
                      <TouchableOpacity
                        style={{
                          position: "absolute",
                          bottom: 5,
                          right: 5,
                          alignItems: "center",
                        }}
                        onPress={() => {
                          axios
                            .post(
                              `${NETWORK_URL}/postApi/like`,
                              {
                                post_id: image._id,
                              },
                              {
                                headers: {
                                  "x-access-token": user.auth_token,
                                },
                              }
                            )
                            .then((response) => {
                              var temp = JSON.parse(JSON.stringify(posts));
                              for (var i = 0; i <= temp.length; i++) {
                                //   alert(temp[i]._id+"_"+image._id);
                                if (temp[i]._id == image._id) {
                                  if (image.is_liked) {
                                    temp[i]["is_liked"] = false;
                                    temp[i]["total_likes"] -= 1;
                                  } else {
                                    temp[i]["is_liked"] = true;
                                    temp[i]["total_likes"] += 1;
                                  }
                                  setposts(temp);
                                }
                              }
                            })
                            .catch(function (error: any) {
                              // alert(JSON.stringify(error));
                            });
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#BEBEBE",
                            padding: 3,
                            borderRadius: 20,
                          }}
                        >
                          <Image
                            source={image.is_liked ? red_heart : blank_heart}
                            style={{ width: 20, height: 20 }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <Text
                          style={{ color: "white", fontSize: 9, marginTop: 3 }}
                        >
                          {image.total_likes}
                        </Text>
                      </TouchableOpacity>
                      {showimage[index] && (
                        <Text
                          style={{
                            position: "absolute",
                            top: 2,
                            right: 8,
                            color: "white",
                            fontSize: 12,
                            width: "60%",
                            textAlign: "right",
                          }}
                          numberOfLines={3}
                          ellipsizeMode="tail"
                        >
                          {image.caption}
                        </Text>
                      )}
                      {(showimage[index] ||
                        selected_image_url.includes(
                          image.image_url[0].url.substring(
                            image.image_url[0].url.indexOf("m3u8") + 4
                          ) +
                          "_|_" +
                          index +
                          "0"
                        ) ||
                        selected_image_url.includes(
                          image.image_url[0].url + "_|_" + index + "0"
                        )) &&
                        subscribed_till != "" && (
                          <View
                            style={{ position: "absolute", top: 5, left: 5 }}
                          >
                            <input
                              id={index}
                              type="checkbox"
                              checked={
                                selected_image_url.includes(
                                  image.image_url[0].url.substring(
                                    image.image_url[0].url.indexOf("m3u8") + 4
                                  ) +
                                  "_|_" +
                                  index +
                                  "0"
                                ) ||
                                selected_image_url.includes(
                                  image.image_url[0].url + "_|_" + index + "0"
                                )
                              }
                              defaultChecked={false}
                              onChange={(event) => {
                                var temp11 = selected_image;
                                var temp12 = selected_image;

                                var temp132 = selected_image_url;
                                var temp142 = selected_image_url;

                                image.image_url.map((image12, index12) => {
                                  // alert(event.currentTarget.checked);

                                  if (event.currentTarget.checked) {
                                    if (image12.url.indexOf("m3u8") > -1)
                                      temp11 = [
                                        ...temp11,
                                        {
                                          url:
                                            image12.url.substring(
                                              image12.url.indexOf("m3u8") + 4
                                            ) +
                                            "_|_" +
                                            index +
                                            index12,
                                          size: image12.size,
                                          postedBy: image.postedBy,
                                        },
                                      ];
                                    else
                                      temp11 = [
                                        ...temp11,
                                        {
                                          url:
                                            image12.url +
                                            "_|_" +
                                            index +
                                            index12,
                                          size: image12.size,
                                          postedBy: image.postedBy,
                                        },
                                      ];

                                    if (image12.url.indexOf("m3u8") > -1)
                                      temp132 = [
                                        ...temp132,
                                        image12.url.substring(
                                          image12.url.indexOf("m3u8") + 4
                                        ) +
                                        "_|_" +
                                        index +
                                        index12,
                                      ];
                                    else
                                      temp132 = [
                                        ...temp132,
                                        image12.url + "_|_" + index + index12,
                                      ];
                                  } else {
                                    if (image12.url.indexOf("m3u8") > -1)
                                      temp12 = temp12.filter(function (
                                        item,
                                        key
                                      ) {
                                        return (
                                          item.url !==
                                          image12.url.substring(
                                            image12.url.indexOf("m3u8") + 4
                                          ) +
                                          "_|_" +
                                          index +
                                          index12
                                        );
                                      });
                                    else
                                      temp12 = temp12.filter(function (
                                        item,
                                        key
                                      ) {
                                        return (
                                          item.url !==
                                          image12.url + "_|_" + index + index12
                                        );
                                      });

                                    if (image12.url.indexOf("m3u8") > -1)
                                      temp142 = temp142.filter(function (
                                        item,
                                        key
                                      ) {
                                        return (
                                          item !==
                                          image12.url.substring(
                                            image12.url.indexOf("m3u8") + 4
                                          ) +
                                          "_|_" +
                                          index +
                                          index12
                                        );
                                      });
                                    else
                                      temp142 = temp142.filter(function (
                                        item,
                                        key
                                      ) {
                                        return (
                                          item !==
                                          image12.url + "_|_" + index + index12
                                        );
                                      });
                                  }
                                });

                                if (event.currentTarget.checked)
                                  setselected_image(temp11);
                                else setselected_image(temp12);

                                if (event.currentTarget.checked)
                                  setselected_image_url(temp132);
                                else setselected_image_url(temp142);
                              }}
                              style={{
                                backgroundColor: "black",
                                height: 20,
                                width: 20,
                              }}
                            />
                            {/* <label for={index}><img style={{ height: 20, width: 20, cursor: "pointer" }} src={(selected_image_url.includes(image.image_url[0].url.substring(image.image_url[0].url.indexOf("m3u8") + 4) + "_|_" + index + "0") || selected_image_url.includes(image.image_url[0].url + "_|_" + index + "0")) ? "https://cdn3.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-1/254000/52-512.png" : "https://upload.wikimedia.org/wikipedia/commons/5/5e/Gray_tick.png"} /></label> */}
                          </View>
                        )}
                    </View>
                  )}
                </Hoverable>
              );
            })}
            ;
          </View>
          {selected_image.length > 0 && (
            <TouchableOpacity
              style={{
                position: "absolute",
                left: 10,
                top: 10,
                padding: 10,
                backgroundColor: "white",
                borderRadius: 50,
              }}
              onPress={() => {
                // alert(JSON.stringify(selected_image));

                var temp = [];

                var download_size = 0;

                selected_image.map(async (image1, index) => {
                  if (image1.url.indexOf(".mp4") > -1) {
                    const res = await axios.post(
                      `${NETWORK_URL}/postApi/download_video`,
                      {
                        image_url: image1.url.substring(
                          0,
                          image1.url.indexOf("_|_")
                        ),
                        size: image1.size,
                        type: "event",
                        postedBy: image1.postedBy,
                      },
                      {
                        headers: {
                          "x-access-token": user.auth_token,
                        },
                      }
                    );

                    temp.push(
                      image1.url.substring(0, image1.url.indexOf("_|_"))
                    );
                  } else {
                    const res = await axios.post(
                      `${NETWORK_URL}/postApi/download`,
                      {
                        image_url: image1.url.substring(
                          0,
                          image1.url.indexOf("_|_")
                        ),
                        size: image1.size,
                        type: "event",
                        postedBy: image1.postedBy,
                      },
                      {
                        headers: {
                          "x-access-token": user.auth_token,
                        },
                      }
                    );
                    //  alert(res.data.download_url); // (Or whatever)

                    temp.push(res.data.download_url);
                  }

                  download_size += image1.size;

                  if (temp.length >= selected_image.length) {
                    if (
                      download_size >
                      userProfile.planData.maxStorageByte -
                      userProfile.size_json.raw_used &&
                      userProfile?.planData?.maxStorage != -1
                    ) {
                      alert("Download Exceeded Your plan");
                      return false;
                    } else {
                      download_files(temp);
                    }
                  }
                });

                setTimeout(() => {
                  setselected_image([]);
                  setselected_image_url([]);
                }, 3000);
              }}
            >
              <Image
                source={{
                  uri: "https://cdn.iconscout.com/icon/free/png-256/download-button-1723003-1465295.png",
                }}
                style={{ height: 50, width: 50 }}
              />
            </TouchableOpacity>
          )}
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalpaymentpreview}
            onRequestClose={() => {
              setmodalpaymentpreview(!modalpaymentpreview);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView_payment}>
                <View style={{ alignItems: "center" }}>
                  <View style={styles.header}>
                    <TouchableOpacity
                      onPress={() => setmodalpaymentpreview(false)}
                    >
                      <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    height: wp(100) <= 820 ? hp(40) : hp(50),
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Gift
                  </Text>

                  <View
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                      margin: 20,
                    }}
                  >
                    <Image
                      source={{ uri: event_info?.event_image }}
                      style={{ width: 50, height: 50, borderRadius: 50 }}
                      resizeMode={"cover"}
                    />
                    <Text style={{ fontSize: 14, marginTop: 20 }}>
                      {event_info?.name}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                      marginBottom: 30,
                    }}
                  >
                    <TextInput
                      placeholder="Amount to be Gifted"
                      // mode="outlined"
                      defaultValue={amount_donate}
                      value={amount_donate}
                      onChangeText={(text) => {
                        setamount_donate(text);
                      }}
                      // onChangeText={text => isNumber(text)}
                      // outlineColor={"#20CCD6"}
                      // activeOutlineColor={"#20CCD6"}
                      style={{
                        height: hp(4),
                        width: wp(100) <= 426 ? "100%" : "100%",
                        paddingLeft: 10,
                        borderColor: "black",
                        borderWidth: 1,
                        borderRadius: 4,
                      }}
                      keyboardType="number-pad"
                      maxLength={6}
                    />
                  </View>

                  <Text style={{ marginBottom: 5, fontSize: 12 }}>
                    Note : Please enable third party cookies for payment to
                    work!
                  </Text>

                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#FF5C5B",
                      paddingHorizontal: 10,
                      paddingVertical: 8,
                      borderRadius: 20,
                      width: "40%",
                      alignSelf: "center",
                    }}
                    onPress={() => {
                      if (!isNumeric(amount_donate) || amount_donate == "") {
                        alert("Only numbers is allowed");
                        return false;
                      }

                      if (amount_donate > 0 && amount_donate != "") {
                        axios
                          .post(
                            `${NETWORK_URL}/fundApi/payment_test`,
                            {
                              event_id: window.atob(route.params.id),
                              transaction_detail: {},
                              product: {
                                name: "ABC",
                                amount: amount_donate,
                                quantity: 1,
                              },
                            },
                            {
                              headers: {
                                "x-access-token": user.auth_token,
                                "content-type": "application/json",
                              },
                            }
                          )
                          .then((response) => {
                            // alert(JSON.stringify(response.data));

                            setmodalpaymentpreview(false);

                            setTimeout(function () {
                              setmodalpaymentgateway(true);
                            }, 200);

                            // alert(response.data.id);

                            setoptions({
                              // passing the client secret obtained from the server
                              clientSecret: response.data.id,
                            });
                          })
                          .catch(function (error: any) {
                            alert(error.response.data.message);
                          });
                      }
                    }}
                  >
                    <Text style={{ color: "white", fontSize: 12 }}>Gift</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalpaymentgateway}
            onRequestClose={() => {
              setmodalpaymentgateway(!modalpaymentgateway);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView_payment}>
                <View style={{ alignItems: "center" }}>
                  <View style={styles.header}>
                    <TouchableOpacity
                      onPress={() => setmodalpaymentgateway(false)}
                    >
                      <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    height: wp(100) <= 820 ? hp(40) : hp(50),
                    width: "100%",
                  }}
                >
                  {options?.clientSecret && (
                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm />
                    </Elements>
                  )}
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalfundlisting}
            onRequestClose={() => {
              setmodalfundlisting(!modalfundlisting);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView_payment}>
                <View style={{ alignItems: "center" }}>
                  <View style={styles.header}>
                    <TouchableOpacity
                      onPress={() => setmodalfundlisting(false)}
                    >
                      <Text style={{ color: "red", fontSize: 12 }}>Close</Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    height: wp(100) <= 820 ? hp(40) : hp(50),
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Funds
                  </Text>

                  <View
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                      margin: 20,
                    }}
                  >
                    <Text style={{ fontSize: 14, marginTop: 20 }}>
                      Total Funds
                    </Text>
                    <Text
                      style={{ fontSize: 18, marginTop: 5, fontWeight: "bold" }}
                    >
                      {event_info["total_funding"]}$
                    </Text>
                  </View>

                  {!loading ? (
                    <ScrollView style={{ height: "40%" }}>
                      {fundlisting.map((elem1, key) => {
                        return (
                          <View
                            style={{
                              width: "100%",
                              borderStyle: "solid",
                              borderWidth: scale(1),
                              borderColor: "rgba(51, 72, 85, 0.1)",
                              padding: 2,
                              borderRadius: scale(8),
                              backgroundColor: "#fff",
                              marginTop: 10,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Image
                                  source={{
                                    uri: elem1?.user?.profile_picture
                                      ? elem1?.user?.profile_picture
                                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
                                  }}
                                  style={{
                                    height: 50,
                                    width: 50,
                                    borderRadius: 50,
                                  }}
                                  resizeMode={"cover"}
                                />
                                <Text style={{ marginLeft: 10 }}>
                                  {elem1?.user?.fullName}
                                </Text>
                              </View>
                              <Text style={{ marginRight: 10 }}>
                                {elem1?.amount}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </ScrollView>
                  ) : (
                    <ActivityIndicator
                      color="black"
                      size="small"
                      style={{ marginTop: scale(20) }}
                    />
                  )}
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalshowimage}
            onRequestClose={() => {
              setmodalshowimage(!modalshowimage);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <View style={{ alignItems: "center" }}>
                  <View style={styles.header}>
                    <TouchableOpacity onPress={() => setmodalshowimage(false)}>
                      <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    height: wp(100) <= 820 ? hp(40) : hp(90),
                    width: "100%",
                  }}
                >
                  {event_photo?.urls?.length > 0 && (
                    <Swiper>
                      {event_photo?.urls?.map((elem1, key) => {
                        if (elem1.url.indexOf("m3u8") <= -1)
                          return (
                            <View style={{ width: "100%", height: "100%" }}>
                              <Image
                                source={{ uri: elem1.url }}
                                style={{ width: "100%", height: "90%" }}
                                resizeMode={"contain"}
                              />

                              <Text style={{ margin: 10, color: "black" }}>
                                {event_photo.caption}
                              </Text>

                              <TouchableOpacity
                                onPress={() => {
                                  // alert(elem1.substring( elem1.indexOf("m3u8") + 1));

                                  // alert(subscribed_till);

                                  // alert(total_storage);

                                  if (
                                    elem1.size > raw_available &&
                                    subscribed_till == "" &&
                                    event_photo.postedBy != user.userDetails._id
                                  ) {
                                    showupgradealert(true);
                                    return false;
                                  }

                                  if (
                                    subscribed_till == "" &&
                                    total_storage == "0.00 MB" &&
                                    event_photo.postedBy != user.userDetails._id
                                  ) {
                                    // alert("You cant download! Please Subscribe to continue");
                                    showupgradealert(true);
                                    return false;
                                  }

                                  if (subscribed_till != "")
                                    if (
                                      elem1.size >
                                      userProfile.planData.maxStorageByte -
                                      userProfile.size_json.raw_used &&
                                      event_photo.postedBy !=
                                      user.userDetails._id &&
                                      userProfile?.planData?.maxStorage != -1
                                    ) {
                                      showupgradealert(true);
                                      return false;
                                    }

                                  axios
                                    .post(
                                      `${NETWORK_URL}/postApi/download`,
                                      {
                                        image_url: elem1.url,
                                        size: elem1.size,
                                        type: "event",
                                        postedBy: event_photo.postedBy,
                                      },
                                      {
                                        headers: {
                                          "x-access-token": user.auth_token,
                                        },
                                      }
                                    )
                                    .then((response) => {
                                      downloadURI(
                                        response.data.download_url,
                                        "sample.jpeg"
                                      );
                                    })
                                    .catch(function (error: any) {
                                      alert(error.response.data);
                                    });

                                  // createFile(elem1);

                                  setTimeout(() => {
                                    setselected_image([]);
                                    setselected_image_url([]);
                                  }, 3000);
                                }}
                                style={{
                                  position: "absolute",
                                  right: 10,
                                  bottom: 40,
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://cdn.iconscout.com/icon/free/png-256/download-button-1723003-1465295.png",
                                  }}
                                  style={{ height: 50, width: 50 }}
                                />
                              </TouchableOpacity>
                            </View>
                          );
                        else
                          return (
                            <View style={{ width: "100%", height: "100%" }}>
                              {/* <ReactHlsPlayer
                                                        src={elem1.url.substring(0, elem1.url.indexOf("m3u8") + 4)}
                                                        autoPlay={true}
                                                        controls={false}
                                                        loop={true}
                                                        width={"100%"}
                                                        height={"90%"}
                                                        style={{ position: "relative" }}
                                                    /> */}
                              <Video
                                style={{
                                  width: "100%",
                                  height: "90%",
                                  position: "relative",
                                }}
                                source={{
                                  uri: elem1.url.substring(
                                    elem1.url.indexOf("m3u8") + 4
                                  ),
                                }}
                                useNativeControls={true}
                                resizeMode={"contain"}
                              />
                              <TouchableOpacity
                                onPress={() => {
                                  // alert(elem1.substring(elem1.indexOf("m3u8") + 4));

                                  if (
                                    elem1.size > raw_available &&
                                    subscribed_till == "" &&
                                    event_photo.postedBy != user.userDetails._id
                                  ) {
                                    showupgradealert(true);
                                    return false;
                                  }

                                  if (
                                    subscribed_till == "" &&
                                    total_storage == "0.00 MB" &&
                                    event_photo.postedBy != user.userDetails._id
                                  ) {
                                    // alert("You cant download! Please Subscribe to continue");
                                    showupgradealert(true);
                                    return false;
                                  }

                                  if (subscribed_till != "")
                                    if (
                                      elem1.size >
                                      userProfile.planData.maxStorageByte -
                                      userProfile.size_json.raw_used &&
                                      event_photo.postedBy !=
                                      user.userDetails._id &&
                                      userProfile?.planData?.maxStorage != -1
                                    ) {
                                      showupgradealert(true);
                                      return false;
                                    }

                                  axios
                                    .post(
                                      `${NETWORK_URL}/postApi/download_video`,
                                      {
                                        image_url: elem1.url,
                                        size: elem1.size,
                                        type: "event",
                                        postedBy: event_photo.postedBy,
                                      },
                                      {
                                        headers: {
                                          "x-access-token": user.auth_token,
                                        },
                                      }
                                    )
                                    .then((response) => {
                                      downloadURI(
                                        elem1.url.substring(
                                          elem1.url.indexOf("m3u8") + 4
                                        ),
                                        "sample.jpeg"
                                      );
                                    })
                                    .catch(function (error: any) {
                                      alert(error.response.data);
                                    });

                                  // createFile(elem1);

                                  setTimeout(() => {
                                    setselected_image([]);
                                    setselected_image_url([]);
                                  }, 3000);
                                }}
                                style={{
                                  position: "absolute",
                                  right: 10,
                                  bottom: 40,
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://cdn.iconscout.com/icon/free/png-256/download-button-1723003-1465295.png",
                                  }}
                                  style={{ height: 50, width: 50 }}
                                />
                              </TouchableOpacity>
                            </View>
                          );
                      })}
                    </Swiper>
                  )}
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={expenseVisible}
            onRequestClose={() => {
              setExpenseVisible(false);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView_payment}>
                <View style={{ alignItems: "center" }}>
                  <View style={styles.header}>
                    <TouchableOpacity onPress={() => setExpenseVisible(false)}>
                      <Text style={{ color: "red", fontSize: 12 }}>close</Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    height: wp(100) <= 820 ? hp(40) : hp(50),
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Expenses
                  </Text>

                  <View
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                      margin: 20,
                    }}
                  >
                    <Text style={{ fontSize: 14, marginTop: 20 }}>
                      Total Expenses
                    </Text>
                    <Text
                      style={{ fontSize: 18, marginTop: 5, fontWeight: "bold" }}
                    >
                      ${expense_total}
                    </Text>
                  </View>

                  <TouchableOpacity
                    onPress={() => {
                      setexpensesplit(!expensesplit);
                    }}
                    style={{
                      width: 70,
                      borderRadius: 10,
                      alignSelf: "flex-end",
                    }}
                  >
                    <LinearGradient
                      start={{ x: 0, y: 0 }}
                      end={{ x: 1, y: 0 }}
                      colors={["#FF5C5B", "#20CCD6"]}
                      style={{ width: "100%", borderRadius: 10 }}
                    >
                      <Text
                        style={{
                          color: "white",
                          marginVertical: 5,
                          alignSelf: "center",
                        }}
                      >
                        {expensesplit ? "Expense" : "Split"}
                      </Text>
                    </LinearGradient>
                  </TouchableOpacity>

                  <ScrollView style={{ height: "40%" }}>
                    {modalExpenseListing.map((elem1: any, key: any) => {
                      return (
                        <View
                          style={{
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: scale(1),
                            borderColor: "rgba(51, 72, 85, 0.1)",
                            padding: 2,
                            borderRadius: scale(8),
                            backgroundColor: "#fff",
                            marginTop: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Image
                                source={{
                                  uri: elem1?.user?.profile_picture
                                    ? elem1?.user?.profile_picture
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
                                }}
                                style={{
                                  height: 50,
                                  width: 50,
                                  borderRadius: 50,
                                }}
                                resizeMode={"cover"}
                              />
                              <Text style={{ marginLeft: 10 }}>
                                {elem1?.user?.fullName}
                              </Text>
                            </View>
                            <Text style={{ marginRight: 10 }}>
                              {elem1?.amount.toFixed(2)}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </ScrollView>
                </View>
              </View>
            </View>
          </Modal>
          {/* <ToastContainer /> */}

          <UpgradeSelect
            planselect={planselect}
            setplanselect={setplanselect}
            upgradealert={upgradealert}
            showupgradealert={showupgradealert}
          />
        </ScrollView>
      }
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
    overflowX: "hidden",
  },
  orHolder: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 15,
  },
  orText: {
    color: colors.secondary,
    backgroundColor: "#fff",
    fontSize: scale(14),
  },
  SocialMediaButtonHolder: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: hp(3),
  },
  socislMediaButton: {
    height: wp(100) <= 768 ? wp(6) : wp(1.5),
    width: wp(100) <= 768 ? wp(6) : wp(1.5),
    marginHorizontal: wp(100) <= 768 ? wp(10) : wp(2),
  },
  storeMediaButton: {
    height: wp(100) <= 768 ? wp(30) : wp(3.5),
    width: wp(100) <= 768 ? wp(30) : wp(8.5),
    marginHorizontal: wp(100) <= 768 ? wp(10) : scale(7),
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: wp(100) <= 820 ? wp(80) : wp(45),
    maxHeight: wp(100) <= 820 ? "auto" : hp(95),
  },
  modalView_payment: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: wp(100) <= 820 ? wp(80) : wp(20),
    maxHeight: wp(100) <= 820 ? "auto" : hp(95),
  },
  header: {
    width: "100%",
    height: wp(100) <= 426 ? 10 : 40,
    alignItems: "flex-end",
    justifyContent: "center",
  },
});
