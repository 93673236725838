import { View, Text, TouchableOpacity, Image, Alert } from 'react-native';
import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { scale, wp, colors, hp } from '../utils';
import { AntDesign } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { ProgressBar, TextInput } from 'react-native-paper';
import InputAutoSuggest from './InputAutoSuggest';
import { NETWORK_URL } from '../utils/config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../reduxStore/store';
import { CommonActions } from '@react-navigation/native';
import * as VideoThumbnails from 'expo-video-thumbnails';
import LinearGradient from 'react-native-web-linear-gradient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var blobs = {};

function readFile(targetFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => {
      reject(new Error(`Failed to read the selected media because the operation failed.`));
    };
    reader.onload = ({ target }) => {
      const uri = target.result;
      const returnRaw = () => resolve({
        uri,
        width: 0,
        height: 0,
        cancelled: false,
        type: "image"
      });
      returnRaw();
    };
    reader.readAsDataURL(targetFile);
  });
}

const CreatePostScreen = ({ get_upload_status, uploading_goingon, setmodaladdpost, total_storage, subscribed_till, raw_available, showupgradealert }) => {

  const [profile, setprofile] = React.useState([]);

  const [title, settitle] = React.useState("");

  const [bodys, setbody] = React.useState("");

  const [event, setevent] = React.useState("");

  const user = useSelector((state: RootState) => state.userReducer);

  const [processing, setProcessing] = React.useState(false);

  const [progress_no, setprogress_no] = React.useState(0);

  const [progress_text, setprogress_text] = React.useState("");

  const notify = (text) => toast(text);

  const [userProfile, setUserProfile] = React.useState({})


  const _launchImageLibrary = async (index: number) => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
      allowsMultipleSelection: true
    });

    // alert(JSON.stringify(result.file));

    if (!result.cancelled) {

      var temp = JSON.parse(JSON.stringify(profile));

      var size_selected = 0;

      await Promise.all(
        result.file.map(async (elem1, key) => {

          if (elem1.type.toString().indexOf("video") > -1) {
            var random = (Math.floor(Math.random() * (999999 + 1)) + 2);
            blobs[random] = elem1;

            var temp1 = {};
            temp1["uri"] = "https://www.nicepng.com/png/detail/11-118375_citekeeper-is-a-crowdsourced-play-icon-white-png.png";
            temp1["type"] = "video";
            temp1["blob"] = random;
            temp1["mime"] = elem1.type.replace(/(.*)\//g, '');
            temp1["size"] = elem1.size;

            size_selected += elem1.size;

            temp[index + key] = temp1;

          }
          else {

            const result = await readFile(elem1);

            // alert(result.uri);
            var temp1 = {};
            temp1["uri"] = result.uri;
            temp1["type"] = "image";
            temp1["blob"] = "";
            temp1["size"] = elem1.size;

            size_selected += elem1.size;

            temp[index + key] = temp1;

          }
        })
      )

      if (size_selected > raw_available && subscribed_till == "") {

        showupgradealert(true);
        return false;

      }

      setprofile(temp);

    }
  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  function get_user_profile() {
    axios.get(`${NETWORK_URL}/userApi`, {
      headers: {
        "x-access-token": user.auth_token
      }
    })
      .then((response) => {
        setUserProfile(response.data)
      })
      .catch(function (error: any) {
        console.log(error.response.data.message);
      });
  }

  React.useEffect(() => {

    get_user_profile()

  }, [])

  function upload() {
    window.onbeforeunload = function (e) {
      return "Upload is going on! Please stay on this page untill completion";
    };

    // const urlParams = new URLSearchParams(window.location.search);
    // const event_id = urlParams.get('event_id');

    var event_id;

    if(window.location.href.indexOf("/home") > -1){}
    else{
      event_id = window.location.href.substring(window.location.href.lastIndexOf("/")+1);
    }

    let body = new FormData();

    var flag_empty = true;

    var files_info: any[] = [];

    var upload_size = 0;

    profile.map((elem1, key) => {

      if (elem1.type != "") {

        flag_empty = false;

        if (elem1.type == "video") {
          body.append("files", new File([blobs[elem1.blob]], "sample." + elem1.mime));
        }
        else {
          body.append("files", dataURLtoFile(elem1.uri, "sample" + key));
        }

        upload_size += elem1.size;

      }

    });



    if (flag_empty) {
      // alert("Please add atleast one photo");
      notify("Please add atleast one photo")
      return false;
    }

    // if (!bodys && event_id) {
    //   notify("Please add caption")
    //   return false;
    // }

    if (!event_id && total_storage == "0.00 MB" && subscribed_till == "") {
      // alert("Cant upload in profile picture! Please subscribe");
      notify("Cant upload in profile picture! Please subscribe")
      return false;
    }

    if (subscribed_till != "")
      if (!event_id && (userProfile.planData.maxStorageByte - (userProfile.size_json.raw_used)) < upload_size && subscribed_till != "" && userProfile?.planData?.maxStorage != -1) {
        alert("Cant Upload Post. Download Exceeded Your plan ");
        return false;
      }

    event_id && body.append("event_id", window.atob(event_id));
    body.append("caption", bodys);

    setProcessing(true);

    setprogress_text("Uploading ....")

    uploading_goingon(true);

    axios.post(`${NETWORK_URL}/postApi/posts_web`,
      body, {
      headers: {
        "x-access-token": user.auth_token,
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: async (progressEvent) => {
        // let { progress } = this.state;
        var progress = Math.floor(((progressEvent.loaded / (1024)) / upload_size) * 100000);

        await new Promise(r => setTimeout(r, 100));

        setprogress_no(progress);
        setprogress_text("Uploading ....")
        if (progress >= 100) {
          setTimeout(function () {
            get_upload_status();
          }, 1000);
        }
      }
    }).then(res => {

      setProcessing(false);

      setprogress_no(100);

      setprogress_text("");

      setmodaladdpost(false);

      notify("Post Created Succesully!");

      setprogress_no(0);

      setTimeout(function () {
        window.location.reload();
      }, 2000);

      window.onbeforeunload = function (e) {

      };

    }).catch(err => {

      setprogress_no(0);
      setProcessing(false);
      alert(err.response.data.message);

      window.location.reload();

    })
  }

  return (
    <SafeAreaView style={{ margin: wp(100) <= 426 ? 5 : 10 }}>

      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
        <Text style={{ fontSize: 18, alignSelf: "center" }}>{profile.length} Selected</Text>
        {progress_no == 0 && window.location.href.indexOf("/home") > -1 && <TouchableOpacity
          onPress={() => {

            upload()


          }} style={{ borderRadius: 30, marginLeft: 10, alignSelf: "center" }}>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", height: "100%", borderRadius: 30, alignItems: "center", justifyContent: "center" }}>
            <Text style={{ color: "white", alignSelf: "center", marginHorizontal: 10, marginVertical: 5 }}>Upload</Text>
          </LinearGradient>

        </TouchableOpacity>}
      </View>

      {progress_no != 0 &&
        <View style={{ width: wp(40), alignItems: "center", alignSelf: "center" }}>
          <ProgressBar style={{ width: wp(40), height: hp(2) }} progress={progress_no * 0.01} color="#20CCD6" />
          <Text style={{ marginTop: 10, fontSize: 18 }}>{progress_text}</Text>
        </View>}

      <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginTop: 10 }}>
        {profile.map((elem1, key) => {

          return (
            <TouchableOpacity
              onPress={() => {

                _launchImageLibrary(key);

              }} style={{ margin: 10, backgroundColor: "white", borderRadius: wp(0.2), width: wp(100) <= 426 ? wp(15) : wp(7), height: wp(100) <= 426 ? wp(15) : wp(7), alignItems: "center", justifyContent: "center" }}>

              {elem1.uri ? <Image style={{ width: "100%", height: "100%", borderRadius: wp(0.2) }} resizeMode={"cover"} source={{ uri: elem1.uri }} /> :
                <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                  <AntDesign name="picture" size={25} color="white" />
                </LinearGradient>
              }

              <TouchableOpacity style={{ position: "absolute", top: 10, left: 5 }}
                onPress={() => {

                  var temp = JSON.parse(JSON.stringify(profile));
                  temp = temp.filter(function (item1, key1) {
                    return key1 !== key
                  })
                  setprofile(temp);

                }}>
                <Image source={{ uri: "https://cdn-icons-png.flaticon.com/512/60/60994.png" }} style={{ height: 10, width: 10 }} />
              </TouchableOpacity>
            </TouchableOpacity>
          )

        })}



        <TouchableOpacity onPress={() => {

          setprofile([...profile])

          _launchImageLibrary(profile.length);

        }} style={{ backgroundColor: colors.primary, borderRadius: wp(0.2), width: wp(100) <= 426 ? wp(15) : wp(7), height: wp(100) <= 426 ? wp(15) : wp(7), alignItems: "center", justifyContent: "center" }}>

          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
            <AntDesign name="plus" size={25} color="white" />
          </LinearGradient>

        </TouchableOpacity>


      </View>

      <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
        {window.location.href.indexOf("/home") <= -1 && <TextInput
          label="Hashtags"
          mode="outlined"
          value={bodys}
          onChangeText={text => setbody(text)}
          outlineColor={"#20CCD6"}
          activeOutlineColor={"#20CCD6"}
          style={{ height: hp(4), width: wp(100) <= 426 ? "85%" : "95%" }}
          maxLength={100}
        />}
        {progress_no == 0 && window.location.href.indexOf("/home") <= -1 && <TouchableOpacity
          onPress={() => {

            upload()


          }} style={{ width: 30, height: 30, borderRadius: 30, marginLeft: 10, alignSelf: "center" }}>
          <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", height: "100%", borderRadius: 30, alignItems: "center", justifyContent: "center" }}>
            <AntDesign name="right" size={25} color="white" />
          </LinearGradient>

        </TouchableOpacity>}
      </View>
      {/* <ToastContainer /> */}
    </SafeAreaView>
  );
};

export default CreatePostScreen;