import React from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
} from "react-native";
import LinearGradient from "react-native-web-linear-gradient";

const windowWidth = Dimensions.get("window").width;

console.log(windowWidth);

const Footer_Duplicate = () => {
  const handleDisplayModal = (value: string) => {
    if (value == "faq") {
      window.open("https://asset.snapyfi.com/pages/faq.html");
    } else if (value == "ts") {
      window.open("https://asset.snapyfi.com/pages/terms-and-conditions.html");
    } else if (value == "pp") {
      window.open("http://asset.snapyfi.com/pages/pricacy-policy.html");
    }
  };
  return (
    <>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={["#FF5C5B", "#20CCD6"]}
        style={{ width: "100%" }}
      >
        <View style={{ padding: 2 }}></View>
      </LinearGradient>
      {windowWidth <= 426 ? (
        <View
          style={[
            styles.container,
            {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
            },
          ]}
        >
          <TouchableOpacity onPress={() => handleDisplayModal("ts")}>
            <Text style={styles.text}>Terms and Conditions</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleDisplayModal("faq")}>
            <Text style={styles.text}>FAQ</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleDisplayModal("pp")}>
            <Text style={styles.text}>Privacy Policy</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View
          style={[
            styles.container,
            {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            },
          ]}
        >
          <TouchableOpacity onPress={() => handleDisplayModal("ts")}>
            <Text style={styles.text}>Terms and Conditions</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleDisplayModal("faq")}>
            <Text style={styles.text}>FAQ</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleDisplayModal("pp")}>
            <Text style={styles.text}>Privacy Policy</Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
};

export default Footer_Duplicate;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "black",
    overflowX: "hidden",
    paddingHorizontal: "20%",
    paddingVertical: "1%",
  },
  text: {
    color: "#fff",
  },
});
