

export const logo = {uri: "https://asset.snapyfi.com/web_assets/images/logo.png"}  //require('./images/logo.png');
export const login_img = {uri: "https://asset.snapyfi.com/web_assets/images/login_img.png"}  //require('./images/login_img.png');
export const login_img_1 = require('./images/login_img_2.png');
export const google_logo = {uri: "https://asset.snapyfi.com/web_assets/images/googl_login.png"}  //require('./images/googl_login.png');
export const facebook_logo = {uri: "https://asset.snapyfi.com/web_assets/images/fb_login.png"}  //require('./images/fb_login.png');
export const app_store = {uri: "https://asset.snapyfi.com/web_assets/images/APP.png"}  //require('./images/APP.png');
export const play_store = {uri: "https://asset.snapyfi.com/web_assets/images/goo.png"}  //require('./images/goo.png');
export const header_logo = {uri: "https://asset.snapyfi.com/web_assets/images/header_logo.png"}  //require('./images/header_logo.png');
export const search_icon = {uri: "https://asset.snapyfi.com/web_assets/images/search.png"}  //require('./images/search.png');
export const cloud_icon = {uri: "https://asset.snapyfi.com/web_assets/images/cloud.png"}  //require('./images/cloud.png');
export const upgrade_icon = {uri: "https://asset.snapyfi.com/web_assets/images/upgrade_icon.png"}  //require('./images/upgrade_icon.png');
export const upload_icon = {uri: "https://asset.snapyfi.com/web_assets/images/upload_icon.png"}  //require('./images/upload_icon.png');
export const arrow_left = {uri: "https://asset.snapyfi.com/web_assets/images/arrow_left.png"}  //require('./images/arrow_left.png');
export const right_arrow_bg = {uri: "https://asset.snapyfi.com/web_assets/images/right_arrow_bg.png"}  //require('./images/right_arrow_bg.png')
export const left_arrow_bg = {uri: "https://asset.snapyfi.com/web_assets/images/left_arrow_bg.png"}  //require('./images/left_arrow_bg.png')
export const down_arrow = {uri: "https://asset.snapyfi.com/web_assets/images/down_arrow.png"}  //require('./images/down_arrow.png')
export const red_heart = {uri: "https://asset.snapyfi.com/web_assets/images/red_heart.png"}  //require('./images/red_heart.png')
export const blank_heart = {uri: "https://asset.snapyfi.com/web_assets/images/blank_heart.png"}  //require('./images/blank_heart.png')
export const funding_money = {uri: "https://asset.snapyfi.com/web_assets/images/Vector.png"}  //require('./images/Vector.png')
export const btn_donate = {uri: "https://asset.snapyfi.com/web_assets/images/btn_donate.png"}  //require('./images/btn_donate.png')
export const filter_icon = {uri: "https://asset.snapyfi.com/web_assets/images/filter_icon.png"}  //require('./images/filter_icon.png')
export const share_icon = {uri: "https://asset.snapyfi.com/web_assets/images/share_icon.png"}  //require('./images/share_icon.png')
export const expense_icon = {uri: "https://asset.snapyfi.com/web_assets/images/expense_icon.png"}  //require('./images/expense_icon.png')
export const hide = require('./images/hide.png')  //require('./images/expense_icon.png')
export const view = require('./images/view.png')  //require('./images/expense_icon.png')
