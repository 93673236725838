import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, ScrollView, Image, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { scale, colors, device, wp, hp } from '../utils';
import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import { initializeApp } from 'firebase/app';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { logo, app_store, play_store, login_img, down_arrow, hide, view } from '../assets'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reduxStore/store';
import { NETWORK_URL, country_code } from '../utils/config';
import { storeToken, userDetails } from '../reduxStore/actions';
import { CommonActions } from '@react-navigation/native';
import LinearGradient from 'react-native-web-linear-gradient';
import SelectPicker from 'react-native-form-select-picker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

WebBrowser.maybeCompleteAuthSession();

const Tab = createMaterialTopTabNavigator();

initializeApp({
    apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
    authDomain: "farmhomes-bcefb.firebaseapp.com",
    projectId: "farmhomes-bcefb",
    storageBucket: "farmhomes-bcefb.appspot.com",
    messagingSenderId: "718737440796",
    appId: "1:718737440796:web:395567042f5eee01329fd9",
    measurementId: "G-NGCNHJQGG5"
});

export default function ForgetPassword({ navigation, route }: any) {

    const [mobile, setmobile] = useState<any>("")
    const [processing, setProcessing] = useState(false);
    const [country, setcountry] = useState<any>("+" + country_code[0].code)
    const [message, setMessage] = useState<any>(null)
    const [error, setError] = useState<String | null>(null);
    const [password, setpassword] = useState<any>("")
    const [confirm_password, setconfirm_password] = useState<any>("")

    const [show_password, setshow_password] = useState<any>(false)

    const [show_password_confirm, setshow_password_confirm] = useState<any>(false)

    const notify = (text) => toast(text);

    const handleVerify = (values: any) => {

        if (!values.password) {
            notify("Password cant be empty")
            return false;
        }

        if (values.password !== values.confirm_password) {
            notify("Confirm Password is not matching")
            return false;
        }

        axios.put(`${NETWORK_URL}/passwordApi/update`, {
            password_token: route.params.password_token,
            new_password: values.password
        })
            .then((response: any) => {

                if (response.status === 200) {
                    setMessage("Password Changed")
                    notify("Password Changed Successfully!")

                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [
                                { name: 'Login' },
                            ],
                        })
                    );

                    if (response.status) {

                    }
                }
                else {

                }

            })
            .catch(function (error) {
                setError('Something Went Wrong')
                console.log(error);
                // alert(JSON.stringify(error.response.data));
            });
    }

    return (
        <View style={styles.container}>

            <View style={{ backgroundColor: "white", elevation: 5, width: wp(100) <= 768 ? wp(90) : "25%", alignSelf: 'center', marginTop: hp(10), paddingBottom: hp(1), borderRadius: wp(2) }}>

                <Image source={logo} style={{ position: "absolute", alignSelf: "center", width: 80, height: 80, borderRadius: 80, top: -40 }} resizeMode={"contain"} />

                <Text style={{ color: "black", marginTop: hp(5.5), marginLeft: wp(2.5), fontSize: 20, fontWeight: "bold", textDecorationLine: 'underline' }}>Verification</Text>

                <View style={{ flexDirection: "row", marginHorizontal: wp(2.5), alignItems: "center", justifyContent: "space-between" }}>
                    <Text style={{ marginTop: 10, fontSize: 12 }}>Please enter your Mobile number to receive the Instructions to reset your password</Text>
                </View>

                <View>

                    <View style={{ width: "90%", justifyContent: "center", marginLeft: "5%" }}>
                        <TextInput
                            style={{ width: "100%", borderRadius: 10, borderWidth: 0.2, padding: 15, marginTop: hp(4), borderColor: "#c9c9c9" }}
                            placeholder={'Password'}
                            value={password}
                            secureTextEntry={!show_password}
                            onChangeText={(text) => { setpassword(text) }}
                        />
                        <TouchableOpacity style={{ position: "absolute", right: 10, top: "53%" }} onPress={() => {
                            setshow_password(!show_password)
                        }}>
                            <Image source={show_password ? hide : view} style={{ width: 20, height: 20 }} resizeMode={"contain"} />
                        </TouchableOpacity>
                    </View>
                    
                    <View style={{ width: "90%", justifyContent: "center", marginLeft: "5%" }}>

                        <TextInput
                            style={{ width: "100%", borderRadius: 10, borderWidth: 0.2, padding: 15, marginTop: hp(4), borderColor: "#c9c9c9" }}
                            placeholder={'Confirm Password'}
                            value={confirm_password}
                            secureTextEntry={!show_password_confirm}
                            onChangeText={(text) => { setconfirm_password(text) }}
                        />
                        <TouchableOpacity style={{ position: "absolute", right: 10, top: "53%" }} onPress={() => {
                            setshow_password_confirm(!show_password_confirm)
                        }}>
                            <Image source={show_password_confirm ? hide : view} style={{ width: 20, height: 20 }} resizeMode={"contain"} />
                        </TouchableOpacity>

                    </View>

                    <TouchableOpacity
                        style={{ backgroundColor: colors.primary, width: "90%", marginLeft: "5%", marginTop: hp(4), borderRadius: wp(2) }}
                        onPress={() => {

                            if (processing) return true;

                            handleVerify({ password: password, confirm_password: confirm_password });

                        }}>
                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", borderRadius: 20 }}>
                            {processing ? <ActivityIndicator size="small" color="#ffffff" style={{ marginVertical: hp(2) }} /> : <Text style={{ alignSelf: "center", marginVertical: hp(2), fontSize: 18, color: "white" }}>Submit</Text>}
                        </LinearGradient>
                    </TouchableOpacity>

                </View>

            </View>

            <Text style={{ color: "white", alignSelf: "center", marginTop: hp(3), fontSize: 18 }}>Get the app</Text>

            <View style={styles.SocialMediaButtonHolder}>
                <TouchableOpacity onPress={() => { }}>
                    <Image style={styles.storeMediaButton} source={app_store} resizeMode={"contain"} />
                </TouchableOpacity>
                <View style={{ height: scale(8), width: 1, backgroundColor: "#c9c9c9" }}></View>
                <TouchableOpacity onPress={() => { }}>
                    <Image style={styles.storeMediaButton} source={play_store} resizeMode={"contain"} />
                </TouchableOpacity>
            </View>
            <ToastContainer />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        overflowX: "hidden"
    },
    orHolder: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 15
    },
    orText: {
        color: colors.secondary,
        backgroundColor: '#fff',
        fontSize: scale(14)
    },
    SocialMediaButtonHolder: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: hp(3),
        alignSelf: "center"
    },
    socislMediaButton: {
        height: wp(100) <= 768 ? wp(6) : wp(1.5),
        width: wp(100) <= 768 ? wp(6) : wp(1.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : wp(2)
    },
    storeMediaButton: {
        height: wp(100) <= 768 ? wp(30) : wp(3.5),
        width: wp(100) <= 768 ? wp(30) : wp(8.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : scale(7)
    }
});