import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, ScrollView, Image, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { scale, colors, device, wp, hp } from '../utils';
import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import { initializeApp } from 'firebase/app';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { logo, app_store, play_store, login_img, down_arrow, hide, view } from '../assets'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reduxStore/store';
import { NETWORK_URL, country_code } from '../utils/config';
import { storeToken, userDetails } from '../reduxStore/actions';
import { CommonActions } from '@react-navigation/native';
import LinearGradient from 'react-native-web-linear-gradient';
import SelectPicker from 'react-native-form-select-picker';
import { Picker } from '@react-native-picker/picker';

WebBrowser.maybeCompleteAuthSession();

const Tab = createMaterialTopTabNavigator();

initializeApp({
    apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
    authDomain: "farmhomes-bcefb.firebaseapp.com",
    projectId: "farmhomes-bcefb",
    storageBucket: "farmhomes-bcefb.appspot.com",
    messagingSenderId: "718737440796",
    appId: "1:718737440796:web:395567042f5eee01329fd9",
    measurementId: "G-NGCNHJQGG5"
});

export default function SignUp_web({ navigation }: any) {

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<String | null>(null);
    const [message, setMessage] = useState<any>(null)
    const user = useSelector((state: RootState) => state.userReducer);
    const dispatch = useDispatch();
    const [name_text, setname_text] = useState<any>("")
    const [username, setusername] = useState<any>("")
    const [mobile, setmobile_text] = useState<any>("")
    const [email_text, setemail_text] = useState<any>("")
    const [password, setpassword] = useState<any>("")
    const [confirm_password, setconfirm_password] = useState<any>("")
    const [country, setcountry] = useState<any>("+" + country_code[0].code)
    const [country_code_array, setcountry_code_array] = useState<any>([])

    const [show_password, setshow_password] = useState<any>(false)

    const [show_password_confirm, setshow_password_confirm] = useState<any>(false)

    useEffect(() => {

        var temp = [];

        country_code.map((col, colIndex) => {
            temp.push(({ value: "+" + col.code, label: "+" + col.code }));
        })

        setcountry_code_array(temp);

    }, [])

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleRegister = (values: any) => {

        if (!values.full_name) {
            setError("Enter Proper Name");
            return false;
        }

        if (!values.username) {
            setError("Enter Username");
            return false;
        }

        if (!values.mobile) {
            setError("Enter Mobile No");
            return false;
        }

        if (!/^[0-9]\d{9}$/.test(values.mobile)) {
            setError("Enter Proper Mobile No");
            return false;
        }

        values.email = values.email.trim();

        if (!validateEmail(values.email) && values.email != "") {
            setError("Enter Proper Email Id");
            return false;
        }


        if (!values.password) {
            setError("Enter Password");
            return false;
        }

        if (values.password != values.confirm_password) {
            setError("Password not matching");
            return false;
        }

        setProcessing(true)
        setError(null)

        axios.post(`${NETWORK_URL}/authApi/register`,
            values.email != "" ? {
                fullName: values.full_name,
                username: values.username,
                mobileNumber: values.mobile,
                email: values.email,
                password: values.password,
                countryCode: country
            } : {
                fullName: values.full_name,
                username: values.username,
                mobileNumber: values.mobile,
                password: values.password,
                countryCode: country
            })
            .then((response: any) => {
                //   console.log(response.status);
                setProcessing(false)

                alert(response.data.message)

                // if (response.status == 200) {
                navigation.navigate('Otp_web', {
                    telephone: window.btoa(values.mobile),
                    countryCode: country
                });
                // }
                //   if (response.status === 422) {
                //     alert(JSON.stringify(response.data));
                //     setError("User Already Registered")
                //   }
                //   alert(JSON.stringify(response.message));
            })
            .catch(err => {

                setProcessing(false)
                console.log(err);

                setError(err.response.data?.message);

                // if (err?.response?.data?.error?.error[0])
                //     setError(err.response.data?.error.error[0])
                // else
                //     setError("Something went wrong")

            })
    }

    return (
        <View style={styles.container}>

            <View style={{ backgroundColor: "white", elevation: 5, width: wp(100) <= 768 ? wp(90) : "25%", alignSelf: 'center', marginTop: hp(10), paddingBottom: hp(1), borderRadius: wp(2) }}>

                <Image source={logo} style={{ position: "absolute", alignSelf: "center", width: 80, height: 80, borderRadius: 80, top: -40 }} resizeMode={"contain"} />

                <Text style={{ color: "black", marginTop: hp(5.5), marginLeft: wp(2.5), fontSize: 20, fontWeight: "bold", textDecorationLine: 'underline' }}>Create Account</Text>

                <View>

                    <TextInput
                        style={{ width: "90%", marginLeft: "5%", borderRadius: 10, borderWidth: 0.2, padding: 15, marginTop: hp(2), borderColor: "#c9c9c9" }}
                        placeholder={"@User name"}
                        value={username}
                        onChangeText={(text) => { setusername(text) }}
                    />

                    <TextInput
                        style={{ width: "90%", marginLeft: "5%", borderRadius: 10, borderWidth: 0.2, padding: 15, marginTop: hp(4), borderColor: "#c9c9c9" }}
                        placeholder={"Name"}
                        value={name_text}
                        onChangeText={(text) => { setname_text(text) }}
                    />

                    <View style={{ width: "90%", marginLeft: "5%", flexDirection: "row", alignItems: "center", marginTop: hp(4) }}>
                        {wp(100) > 425 && <View style={{ width: "20%" }}>
                            <Picker
                                style={{ borderRadius: wp(0.5), paddingHorizontal: 2, height: 30, width: "100%", borderWidth: 1, borderColor: "#FF5C5B", color: "black" }}
                                selectedValue={country}
                                onValueChange={(itemValue, itemIndex) =>
                                    setcountry(itemValue)
                                }>
                                {
                                    country_code.map((col, colIndex) => {
                                        return <Picker.Item label={"+" + col.code} value={"+" + col.code} />
                                    })
                                }
                            </Picker>
                        </View>}
                        {wp(100) <= 425 && <View style={{ width: "20%" }}>
                            <SelectPicker
                                style={{ borderRadius: wp(0.5), paddingHorizontal: 10, width: "100%", borderWidth: 0, backgroundColor: "#FF5C5B" }}
                                onSelectedStyle={{ color: "white", fontSize: "75%" }}
                                selected={country}
                                onValueChange={(itemValue, itemIndex) =>
                                    setcountry(itemValue)
                                }
                            >

                                {
                                    country_code.map((col, colIndex) => {
                                        return <SelectPicker.Item label={"+" + col.code} value={"+" + col.code} />
                                    })
                                }

                            </SelectPicker>
                            <Image source={down_arrow} style={{ position: "absolute", right: 10, top: "35%", width: 10, height: 10 }} resizeMode={"contain"} />
                        </View>}
                        <TextInput
                            style={{ width: "78%", marginLeft: "2%", borderRadius: 10, borderWidth: 0.2, padding: 15, borderColor: "#c9c9c9" }}
                            placeholder={"Mobile Number"}
                            value={mobile}
                            onChangeText={(text) => { setmobile_text(text) }}
                        />
                    </View>



                    <TextInput
                        style={{ width: "90%", marginLeft: "5%", borderRadius: 10, borderWidth: 0.2, padding: 15, marginTop: hp(4), borderColor: "#c9c9c9" }}
                        placeholder={"Email (Optional)"}
                        value={email_text}
                        onChangeText={(text) => { setemail_text(text) }}
                    />

                    <View style={{ width: "90%", justifyContent: "center", marginLeft: "5%" }}>
                        <TextInput
                            style={{ width: "100%", borderRadius: 10, borderWidth: 0.2, padding: 15, marginTop: hp(4), borderColor: "#c9c9c9" }}
                            placeholder={'Password'}
                            value={password}
                            secureTextEntry={!show_password}
                            onChangeText={(text) => { setpassword(text) }}
                        />
                        <TouchableOpacity style={{ position: "absolute", right: 10, top: "53%" }} onPress={() => {
                            setshow_password(!show_password)
                        }}>
                            <Image source={show_password ? hide : view} style={{ width: 20, height: 20 }} resizeMode={"contain"} />
                        </TouchableOpacity>
                    </View>

                    <View style={{ width: "90%", justifyContent: "center", marginLeft: "5%" }}>
                        <TextInput
                            style={{ width: "100%", borderRadius: 10, borderWidth: 0.2, padding: 15, marginTop: hp(4), borderColor: "#c9c9c9" }}
                            placeholder={'Confirm Password'}
                            value={confirm_password}
                            secureTextEntry={!show_password_confirm}
                            onChangeText={(text) => { setconfirm_password(text) }}
                        />
                        <TouchableOpacity style={{ position: "absolute", right: 10, top: "53%" }} onPress={() => {
                            setshow_password_confirm(!show_password_confirm)
                        }}>
                            <Image source={show_password_confirm ? hide : view} style={{ width: 20, height: 20 }} resizeMode={"contain"} />
                        </TouchableOpacity>
                    </View>

                    {error !== null && <Text style={{ alignSelf: "center", color: 'red' }}>{error}</Text>}

                    <TouchableOpacity
                        style={{ backgroundColor: colors.primary, width: "90%", marginLeft: "5%", marginTop: hp(4), borderRadius: wp(2) }}
                        onPress={() => {

                            if (processing) return true;

                            handleRegister({ username: username, full_name: name_text, mobile: mobile, email: email_text, password: password, confirm_password: confirm_password });

                        }}>
                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", borderRadius: 20 }}>
                            {processing ? <ActivityIndicator size="small" color="#ffffff" style={{ marginVertical: hp(2) }} /> : <Text style={{ alignSelf: "center", marginVertical: hp(2), fontSize: 18, color: "white" }}>Sign Up</Text>}
                        </LinearGradient>
                    </TouchableOpacity>


                </View>

            </View>

            <View style={{ backgroundColor: "white", elevation: 5, width: wp(100) <= 768 ? wp(90) : "25%", height: hp(10), alignSelf: 'center', marginTop: hp(2), borderRadius: wp(2) }}>

                <Text style={{ alignSelf: "center", marginTop: hp(3.8), fontSize: 16 }}> Already have an account ?
                    <TouchableOpacity style={{ marginLeft: scale(2) }} onPress={() => {

                        navigation.navigate("Login");

                    }}>
                        <Text style={{ color: "black", fontWeight: "bold" }}>Log In</Text>
                    </TouchableOpacity>

                </Text>

            </View>

            <Text style={{ color: "white", alignSelf: "center", marginTop: hp(3), fontSize: 18 }}>Get the app</Text>

            <View style={styles.SocialMediaButtonHolder}>
                <TouchableOpacity onPress={() => { window.open("https://apps.apple.com/in/app/snapyfi/id6472412812"); }}>
                    <Image style={styles.storeMediaButton} source={app_store} resizeMode={"contain"} />
                </TouchableOpacity>
                <View style={{ height: scale(8), width: 1, backgroundColor: "#c9c9c9" }}></View>
                <TouchableOpacity onPress={() => { window.open("https://play.google.com/store/apps/details?id=com.tru.snapify"); }}>
                    <Image style={styles.storeMediaButton} source={play_store} resizeMode={"contain"} />
                </TouchableOpacity>
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        overflowX: "hidden"
    },
    orHolder: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 15
    },
    orText: {
        color: colors.secondary,
        backgroundColor: '#fff',
        fontSize: scale(14)
    },
    SocialMediaButtonHolder: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: hp(3),
        alignSelf: "center"
    },
    socislMediaButton: {
        height: wp(100) <= 768 ? wp(6) : wp(1.5),
        width: wp(100) <= 768 ? wp(6) : wp(1.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : wp(2)
    },
    storeMediaButton: {
        height: wp(100) <= 768 ? wp(30) : wp(3.5),
        width: wp(100) <= 768 ? wp(30) : wp(8.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : scale(7)
    }
});