import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import { Provider } from "react-redux";
import { store, persistor } from "./src/reduxStore/store";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { PersistGate } from "redux-persist/lib/integration/react";

import Landing_web from "./src/screens/Landing_web";
import Signup_web from "./src/screens/Signup_web";
import Home from "./src/screens/Home";

import Otp_web from "./src/screens/Otp_web";

import EventHome from "./src/screens/EventHome";

import ForgetPassword from "./src/screens/ForgetPassword";

import Otp_forgot_send from "./src/screens/Otp_forgot_send";

import Change_password from "./src/screens/Change_password";
import Footer_Duplicate from "./src/screens/Footer_Duplicate";
// import AssetLinks from "./src/screens/assetlinks";
// import AppleAppSiteAssociation from "./src/screens/apple-app-site-association";

const navigationRef: any = React.createRef();

const Stack = createStackNavigator();
const App = () => {
  useEffect(() => {}, []);

  return (
    // <View style={styles.container}>
    //   <ThreeTest/>
    //   <RingDesignScreen style="auto" />
    // </View>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <NavigationContainer
            ref={navigationRef}
            linking={{
              config: {
                screens: {
                  Login: "login",
                  Signup: "signup",
                  Home: "home",
                  EventHome: "event/:id",
                  Otp_web: "otp",
                  // assetlinks:".well-known/assetlinks.json",
                  // AppleAppSiteAssociation:".well-known/apple-app-site-association"
                },
              },
            }}
          >
            <BaseStack_web />
            <Footer_Duplicate />
          </NavigationContainer>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
};

const BaseStack_web = () => {
  return (
    <Stack.Navigator
      initialRouteName={"Login"}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="Otp_web" component={Otp_web} />
      {/* <Stack.Screen name="assetlinks" component={AssetLinks} />
      <Stack.Screen name="AppleAppSiteAssociation" component={AppleAppSiteAssociation} /> */}
      <Stack.Screen name="Login" component={Landing_web} />
      <Stack.Screen name="Signup" component={Signup_web} />
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="EventHome" component={EventHome} />
      <Stack.Screen name="ForgetPassword" component={ForgetPassword} />
      <Stack.Screen name="Otp_forgot_send" component={Otp_forgot_send} />
      <Stack.Screen name="Change_password" component={Change_password} />
    </Stack.Navigator>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default App;
