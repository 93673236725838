import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, ScrollView, Image, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { scale, colors, device, wp, hp } from '../utils';
import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import { initializeApp } from 'firebase/app';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { logo, app_store, play_store, login_img } from '../assets'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reduxStore/store';
import { NETWORK_URL } from '../utils/config';
import { storeToken, userDetails } from '../reduxStore/actions';
import { CommonActions } from '@react-navigation/native';
import LinearGradient from 'react-native-web-linear-gradient';

WebBrowser.maybeCompleteAuthSession();

const Tab = createMaterialTopTabNavigator();

initializeApp({
    apiKey: "AIzaSyDdppuzk1Ts_BuqYb7N-KSkT6tttOEU-P8",
    authDomain: "farmhomes-bcefb.firebaseapp.com",
    projectId: "farmhomes-bcefb",
    storageBucket: "farmhomes-bcefb.appspot.com",
    messagingSenderId: "718737440796",
    appId: "1:718737440796:web:395567042f5eee01329fd9",
    measurementId: "G-NGCNHJQGG5"
});

export default function SignUp_web({ navigation, route }: any) {

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<String | null>(null);
    const [message, setMessage] = useState<any>(null)
    const user = useSelector((state: RootState) => state.userReducer);
    const dispatch = useDispatch();
    const [name_text, setname_text] = useState<any>("")
    const [username, setusername] = useState<any>("")
    const [mobile, setmobile_text] = useState<any>("")
    const [email_text, setemail_text] = useState<any>("")
    const [password, setpassword] = useState<any>("")
    const [confirm_password, setconfirm_password] = useState<any>("")

    const [first_text, setfirsttext] = useState<any>("")
    const [second_text, setsecondtext] = useState<any>("")
    const [third_text, setthirdtext] = useState<any>("")
    const [fourth_text, setfourthtext] = useState<any>("")
    const [fifth_text, setfifthtext] = useState<any>("")
    const [sixth_text, setsixthtext] = useState<any>("")

    const secondinputref = useRef(null);
    const thirdinputref = useRef(null);
    const fourthinputref = useRef(null);
    const fiveinputref = useRef(null);
    const sixthinputref = useRef(null);

    const btninputref = useRef(null);

    const [flag, setFlag] = useState(false)
    const [timer, setTimer] = useState(60)
    const [otp, setotp] = useState("")

    useEffect(() => {
        setTimeout(() => {
            if (timer !== 0) {
                setTimer(timer - 1)
                setFlag(false)
            }
            else {
                setFlag(true)
            }

        }, 1000)

    }, [timer])

    useEffect(() => {
        handleResendOtp()
    }, [])

    const handleResendOtp = () => {

        setFlag(false);

        setTimer(60)
        
        axios.post(`${NETWORK_URL}/authApi/otp_send`, {
            telephone: window.atob(route?.params?.telephone),
            countryCode: route.params.countryCode
        })
            .then((response: any) => {
                console.log("OTP Details>>>", response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleVerify = (values: any) => {
        axios.post(`${NETWORK_URL}/authApi/verify_otp`, {
          telephone: window.atob(route?.params?.telephone),
          otp: values.otp,
          countryCode: route.params.countryCode
        })
        .then((response: any) => {
          console.log("OTP Details>>>", response.data);
          // alert(response.data.message)
    
          if (response.status === 200) {
            setMessage("Account Verified! Please login to continue.")
              alert('Account Verified Successfully!');
             
              navigation.dispatch(
                        CommonActions.reset({
                        index: 0,
                        routes: [
                            { name: 'Login' },
                        ],
                        })
                    );
    
            if (response.status) {
              
            }
          }
          else {
            
          }
        })
        .catch(function (error) {
          setError('Invalid Otp, Please try again')
          console.log(error);
        });
      }

    return (
        <View style={styles.container}>

            <View style={{ backgroundColor: "white", elevation: 5, width: wp(100) <= 768 ? wp(90) : "25%", alignSelf: 'center', marginTop: hp(10), paddingBottom: hp(1), borderRadius: wp(2) }}>

                <Image source={logo} style={{ position: "absolute", alignSelf: "center", width: 80, height: 80, borderRadius: 80, top: -40 }} resizeMode={"contain"} />

                <Text style={{ color: "black", marginTop: hp(5.5), marginLeft: wp(2.5), fontSize: 20, fontWeight: "bold", textDecorationLine: 'underline' }}>Verification</Text>

                <View style={{ flexDirection: "row", marginHorizontal: wp(2.5), alignItems: "center", justifyContent: "space-between" }}>
                    {!flag ?
                    <Text style={{color:colors.primary, fontSize: 14, fontWeight: 'bold'}} >Resend Otp In {timer} Seconds</Text>
                    :
                        <TouchableOpacity onPress={() => handleResendOtp()}>
                          <Text style={{ fontWeight: '500', color: "red" }}>Resend Otp</Text>
                        </TouchableOpacity>
                    }
                    {error !== null && <Text style={{ marginTop: 10, fontSize: 12,color:"red" }}>{error}</Text>}
                </View>

                <View>

                    <View style={{ flexDirection: "row", alignItems: "center", marginLeft: wp(2.5), marginTop: hp(2) }}>
                        <TextInput
                            style={{ width: hp(6), height: hp(6), borderRadius: 4, borderColor: "#cecece", borderWidth: 1, textAlign: "center" }}
                            placeholder={""}
                            keyboardType={'decimal-pad'}
                            value={first_text}
                            onChangeText={(text) => { text = text.substring(text.length - 1, text.length); secondinputref?.current?.focus(); setfirsttext(text); }}
                        />
                        <TextInput
                            style={{ width: hp(6), height: hp(6), borderRadius: 4, borderColor: "#cecece", borderWidth: 1, marginLeft: 10, textAlign: "center" }}
                            placeholder={""}
                            keyboardType={'decimal-pad'}
                            value={second_text}
                            ref={secondinputref}
                            onChangeText={(text) => { text = text.substring(text.length - 1, text.length); thirdinputref?.current?.focus(); setsecondtext(text) }}
                        />
                        <TextInput
                            style={{ width: hp(6), height: hp(6), borderRadius: 4, borderColor: "#cecece", borderWidth: 1, marginLeft: 10, textAlign: "center" }}
                            placeholder={""}
                            keyboardType={'decimal-pad'}
                            value={third_text}
                            ref={thirdinputref}
                            onChangeText={(text) => { text = text.substring(text.length - 1, text.length); fourthinputref?.current?.focus(); setthirdtext(text) }}
                        />
                        <TextInput
                            style={{ width: hp(6), height: hp(6), borderRadius: 4, borderColor: "#cecece", borderWidth: 1, marginLeft: 10, textAlign: "center" }}
                            placeholder={""}
                            keyboardType={'decimal-pad'}
                            value={fourth_text}
                            ref={fourthinputref}
                            onChangeText={(text) => { text = text.substring(text.length - 1, text.length); fiveinputref?.current?.focus(); setfourthtext(text) }}
                        />
                        <TextInput
                            style={{ width: hp(6), height: hp(6), borderRadius: 4, borderColor: "#cecece", borderWidth: 1, marginLeft: 10, textAlign: "center" }}
                            placeholder={""}
                            keyboardType={'decimal-pad'}
                            value={fifth_text}
                            ref={fiveinputref}
                            onChangeText={(text) => { text = text.substring(text.length - 1, text.length); sixthinputref?.current?.focus(); setfifthtext(text) }}
                        />
                        <TextInput
                            style={{ width: hp(6), height: hp(6), borderRadius: 4, borderColor: "#cecece", borderWidth: 1, marginLeft: 10, textAlign: "center" }}
                            placeholder={""}
                            keyboardType={'decimal-pad'}
                            value={sixth_text}
                            ref={sixthinputref}
                            onChangeText={(text) => { text = text.substring(text.length - 1, text.length); btninputref?.current?.focus(); setsixthtext(text) }}
                        />
                    </View>

                    <TouchableOpacity
                        ref={btninputref}
                        style={{ backgroundColor: colors.primary, width: "90%", marginLeft: "5%", marginTop: hp(4), borderRadius: wp(2) }}
                        onPress={() => {

                            if (processing) return true;

                            handleVerify({ otp : first_text+second_text+third_text+fourth_text+fifth_text+sixth_text });

                        }}>
                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#FF5C5B', '#20CCD6']} style={{ width: "100%", borderRadius: 20 }}>
                            {processing ? <ActivityIndicator size="small" color="#ffffff" style={{ marginVertical: hp(2) }} /> : <Text style={{ alignSelf: "center", marginVertical: hp(2), fontSize: 18, color: "white" }}>Verify</Text>}
                        </LinearGradient>
                    </TouchableOpacity>

                </View>

            </View>

            <Text style={{ color: "white", alignSelf: "center", marginTop: hp(3), fontSize: 18 }}>Get the app</Text>

            <View style={styles.SocialMediaButtonHolder}>
                <TouchableOpacity onPress={() => { }}>
                    <Image style={styles.storeMediaButton} source={app_store} resizeMode={"contain"} />
                </TouchableOpacity>
                <View style={{ height: scale(8), width: 1, backgroundColor: "#c9c9c9" }}></View>
                <TouchableOpacity onPress={() => { }}>
                    <Image style={styles.storeMediaButton} source={play_store} resizeMode={"contain"} />
                </TouchableOpacity>
            </View>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
        overflowX: "hidden"
    },
    orHolder: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 15
    },
    orText: {
        color: colors.secondary,
        backgroundColor: '#fff',
        fontSize: scale(14)
    },
    SocialMediaButtonHolder: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: hp(3),
        alignSelf: "center"
    },
    socislMediaButton: {
        height: wp(100) <= 768 ? wp(6) : wp(1.5),
        width: wp(100) <= 768 ? wp(6) : wp(1.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : wp(2)
    },
    storeMediaButton: {
        height: wp(100) <= 768 ? wp(30) : wp(3.5),
        width: wp(100) <= 768 ? wp(30) : wp(8.5),
        marginHorizontal: wp(100) <= 768 ? wp(10) : scale(7)
    }
});